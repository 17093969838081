import { FC } from "react";
import { classNames, wrapImage } from "utils";
import numeral from "numeral";
import moment from "moment";
import lodash from "lodash";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { StatusBadge } from "components/core";

interface InstallmentDetailsViewProps {
  installments: any[];
}

const InstallmentDetailsView: FC<InstallmentDetailsViewProps> = ({
  installments,
}) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  if (!installments?.length) {
    return (
      <div
        className={classNames(
          false
            ? "border-amber-400 bg-amber-50 dark:border-amber-600"
            : "border-gray-300 dark:border-gray-600",
          "flex-1 flex flex-col items-center justify-center h-full border-2 border-dashed  rounded-md text-center text-sm"
        )}
      >
        <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-gray-100">
          No Installments
        </h3>
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          No installments have been created for this debt.
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-6 divide-y divide-gray-200 flex-1">
      <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
        <thead className="bg-gray-50">
          <tr className="divide-x divide-gray-200">
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-2 text-center text-sm font-semibold text-gray-900"
            >
              #
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
            >
              Code
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
            >
              Description
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-2 text-right text-sm font-semibold text-gray-900"
            >
              Amount(GHS)
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
            >
              Due Date
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-2 text-center text-sm font-semibold text-gray-900"
            >
              Status
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {installments?.map((installment, idx) => (
            <tr key={idx} className="divide-x divide-gray-200">
              <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-700">
                {idx + 1}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">
                {installment?.code}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">
                {installment?.description}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">
                {numeral(installment?.amount || 0).format("0,0.00")}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                {moment(installment?.dueDate || 0).format(dateFormat)}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-500">
                <StatusBadge
                  status={installment?.status}
                  options={{
                    Pending: "bg-yellow-100 text-yellow-800",
                    Synced: "bg-orange-100 text-orange-800",
                    Paid: "bg-green-100 text-green-800",
                    Cancelled: "bg-red-100 text-red-800",
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InstallmentDetailsView;
