import { gql, useMutation } from "@apollo/client";
import { CompleteCreditForm, Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { wrapClick } from "utils";

const COMPLETE_CREDIT = gql`
  mutation CompletePrepaymentCredit($id: ID!, $notes: String, $token: String) {
    completePrepaymentCredit(id: $id, notes: $notes, token: $token) {
      _id
    }
  }
`;

export default function CompleteCreditContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [completeCredit] = useMutation(COMPLETE_CREDIT);
  const searchParams = useSearch<LocationGenerics>();
  const form = useFormik<any>({
    initialValues: {
      notes: "",
      token: "",
    },
    onSubmit: async (values) => {
      await completeCredit({
        variables: {
          id: searchParams.id,
          ...values,
        },
      }).then(({ data }) => {
        if (data.completePrepaymentCredit._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Credit Completed Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not complete credit",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title='Complete Credit'
      size='4xl'
      description='Provide a details to confirm that customer has been credited successfully.'
      renderActions={() => (
        <>
          <button
            type='button'
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {form.isSubmitting ? "Completing ..." : "Complete"}
          </button>
        </>
      )}
    >
      <CompleteCreditForm form={form} />
    </Modal>
  );
}
