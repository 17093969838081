import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import lodash from "lodash";
import moment from "moment";
import numeral from "numeral";
import { FC } from "react";

interface AccountDataAgingSchedule {
  billReference: string;
  billDate: Date;
  billAmount: number;
  debtAmount: number;
  dueDate: Date;
}
interface CustomerDebtViewProps {
  data: {
    lastPaymentDate: Date;
    lastPaymentAmount: number;
    lastBillDate: Date;
    lastBillAmount: number;
    agingSchedule: AccountDataAgingSchedule[];
    currentBalance: number;
    debtAmount: number;
    toBeDisconnected: Boolean;
  };
}

const CustomerDebtView: FC<CustomerDebtViewProps> = ({ data }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div>
        <span className='text-xs font-light'>Debt Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Debt Amount
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              GHS {numeral(data?.debtAmount || 0).format("0,0.00")}{" "}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Current Balance
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              GHS {numeral(data?.currentBalance || 0).format("0,0.00")}{" "}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              Oldest Debt Age
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {data?.agingSchedule
                ? numeral(
                    moment().diff(moment(lodash.first(data?.agingSchedule)?.dueDate), "days")
                  ).format("0,0") + " days"
                : "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Disconnection Prioritized
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {data?.toBeDisconnected ? "Yes" : "No"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Last Payment Date
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {data?.lastPaymentDate
                ? moment(data.lastPaymentDate).format(dateFormat)
                : "N/A"}{" "}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Last Payment Amount
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              GHS {numeral(data?.lastPaymentAmount || 0).format("0,0.00")}{" "}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Last Bill Date
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {data?.lastBillDate
                ? moment(data.lastBillDate).format(dateFormat)
                : "N/A"}{" "}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Last Bill Amount
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              GHS {numeral(data?.lastBillAmount || 0).format("0,0.00")}{" "}
            </div>
          </div>
        </div>
      </div>

      <div className='pt-6'>
      <span className='text-xs font-light'>Aging Schedule</span>

        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div className='col-start-1 col-span-3'>
            <div className=' w-full overflow-hidden'>
              <table className='min-w-full table-fixed divide-y divide-gray-300 border border-gray-300 rounded-md'>
                <thead className='bg-gray-50'>
                  <tr className='divide-x divide-gray-200'>
                    <th
                      scope='col'
                      className='whitespace-nowrap px-2 py-2 text-center text-sm font-semibold text-gray-900'
                    >
                      #
                    </th>
                    <th
                      scope='col'
                      className='whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-left'
                    >
                      Ref
                    </th>
                    <th
                      scope='col'
                      className='whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-center'
                    >
                      Bill Date
                    </th>
                    <th
                      scope='col'
                      className='whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-right'
                    >
                      Bill Amount
                    </th>
                    <th
                      scope='col'
                      className='whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-right'
                    >
                      Debt Amount
                    </th>
                    <th
                      scope='col'
                      className='whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-center'
                    >
                      Due Date
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {data?.agingSchedule.map((schedule, idx: number) => {
                    return (
                      <tr key={idx} className='divide-x divide-gray-200'>
                        <td className='whitespace- px-2 py-2 text-sm text-gray-700 text-ellipsis overflow-hidden text-center '>
                          {idx + 1}
                        </td>
                        <td className='whitespace- px-2 py-2 text-sm text-gray-700 text-ellipsis overflow-hidden '>
                          {schedule?.billReference || "N/A"}
                        </td>
                        <td className='whitespace-nowrap px-2 py-2 text-sm text-gray-700 text-center'>
                          <div>
                            {schedule?.billDate
                              ? moment(schedule?.billDate).format(dateFormat)
                              : "N/A"}{" "}
                          </div>
                        </td>
                        <td className='whitespace-nowrap px-2 py-2 text-sm text-gray-700 text-right'>
                          GHS{" "}
                          {numeral(schedule?.billAmount || 0).format("0,0.00")}{" "}
                        </td>

                        <td className='whitespace-nowrap px-2 py-2 text-sm text-gray-700 text-right'>
                          GHS{" "}
                          {numeral(schedule?.debtAmount || 0).format("0,0.00")}{" "}
                        </td>
                        <td className='whitespace-nowrap px-2 py-2 text-sm text-gray-700 text-center'>
                          <div>
                            {schedule?.dueDate
                              ? moment(schedule?.dueDate).format(dateFormat)
                              : "N/A"}{" "}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDebtView;
