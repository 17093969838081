import { gql, useMutation, useQuery } from "@apollo/client";
import { DebtView, Modal } from "components";
import { Action } from "components/buttons/action-button";
import BalanceView from "components/cards/balance-view";
import CreditView from "components/cards/credit-view";
import { GET_BALANCE } from "pages/balances/view";
import toast from "react-hot-toast";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick } from "utils";


export default function ViewDebtContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_BALANCE, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  const navigate = useNavigate()

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title='Credit Information'
      description='Details of credit are shown below'
      size='5xl'
      renderActions={() => (
        <>
          {/* {withPermissions(["debts:*", "debts:send"])( */}
          {data?.balance?.prepaymentCreditStatus != "Completed" && (
            <button
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={wrapClick(dispatchAction(searchParams.id as string, "complete"))}
            >
              Complete
            </button>
          )}
        </>
      )}
    >
      <CreditView credit={data?.balance} />
    </Modal>
  );
}
