import { gql, useQuery } from "@apollo/client";
import {
  DebtView,
  InstallmentDetailsView,
  Modal,
  ScheduleHistoryView,
} from "components";
import { useState } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { classNames, withPermissions, wrapClick } from "utils";

export const GET_DEBT = gql`
  query GetDebt($id: ID!) {
    debt: getDebt(id: $id) {
      _id
      code
      meter {
        _id
        code
        modelMeta {
          brandCode
          brandName
          modelCode
          modelName
          systemCode
          systemName
          phase
        }
      }
      servicePoint {
        _id
        code
        serviceClass
        accountCode
        geoCode
        status
        meterCode
        customerCode
        status
        customerType
        customerOrganization {
          name
        }
        customerRepresentative {
          fullName
          profileImageUrl
          phoneNumber
          emailAddress
        }
      }
      data {
        finalReadingDate
        previousPurchases {
          amount
          date
        }
      }
      amount
      type
      status
      scheduledAt
      createdAt
      updatedAt
      createdBy {
        _id
        code
        firstName
        lastName
        emailAddress
        phoneNumber
      }
      scheduledBy {
        _id
        code
        firstName
        lastName
        emailAddress
        phoneNumber
      }
      history {
        actor {
          ... on User {
            _id
            firstName
            lastName
            code
            phoneNumber
            emailAddress
          }
        }
        actorType
        timestamp
        action
        data {
          _id
          code
          amount
          description
          dueDate
          status
        }
        notes
      }
      installments {
        _id
        code
        amount
        description
        dueDate
        status
        sync {
          _id
          code
          stage
          status
        }
      }
    }
  }
`;

const tabs = [
  { name: "Debt Details", href: "details" },
  {
    name: "Installment Details",
    href: "installments",
  },
  {
    name: "Schedule History Details",
    href: "history",
  },
];

export default function ViewDebtContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [orderTab, setOrderTab] = useState("details");
  const { data, loading } = useQuery(GET_DEBT, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: true,
  });

  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };
  const scheduleItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "schedule",
      }),
    });
  };
  const cancelItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "cancel",
      }),
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      hidePadding
      title="Debt Information"
      description="Details of debt are shown below"
      size="5xl"
      renderActions={() => (
        <>
        {["RevenueProtection", "DebtRecovery"].includes(data?.debt?.type) &&
          ["Scheduled", "Pending"].includes(data?.debt?.status) &&
          withPermissions(["debts:*", "debts:cancel"])(
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(cancelItem)}
            >
              Cancel
            </button>
          )}
          {["RevenueProtection", "DebtRecovery"].includes(data?.debt?.type) &&
            ["Scheduled", "Pending"].includes(data?.debt?.status) &&
            withPermissions(["debts:*", "debts:schedule"])(
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={wrapClick(scheduleItem)}
              >
                {data?.debt?.status === "Pending" ? "Schedule" : "Reschedule"}
              </button>
            )}
        </>
      )}
    >
      <div className="block">
        <div className="border-b border-gray-200 bg-white px-6">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs?.map((_status) => (
              <button
                key={_status.name}
                onClick={wrapClick(__setOrderTab(_status.href))}
                className={classNames(
                  orderTab === _status.href
                    ? "border-primary-500 text-primary-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={orderTab === _status.href ? "page" : undefined}
              >
                {_status.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div className="flex-1 w-full flex flex-col min-h-[65vh] max-h-[65vh] overflow-y-auto  sm:p-6">
        {orderTab === "details" && <DebtView debt={data?.debt} />}
        {orderTab === "installments" && (
          <InstallmentDetailsView installments={data?.debt?.installments} />
        )}
        {orderTab === "history" && (
          <ScheduleHistoryView history={data?.debt?.history} />
        )}
      </div>
    </Modal>
  );
}
