
import InstallmentPlanForm from "./installment-plan";


const formSteps = [
  {
    name: 'Installment Plan Info',
    description: "Provide values for the installment plan",
    FormComponent: InstallmentPlanForm,
    accessor: "installmentInfo",
  }
]

export default formSteps;
