import { Modal } from "components";
import { FC } from "react";
import ManageDebtors from "./manage-debtors";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";

const DebtorsModal: FC<{
  open: boolean;
  setOpen: (val: boolean) => void;
}> = ({ open, setOpen }) => {
  const searchParams = useSearch<LocationGenerics>();

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      hidePadding
      title="Debtors In Plot"
      description="Debts in plots are shown below"
      size="7xl"
    >
      <ManageDebtors plot={searchParams?.id} prefix="debtor" />
    </Modal>
  );
};

export default DebtorsModal;
