export default [
    { "name": "", "value": "*:*" },

    { "name": "", "value": "customers:*" },
    { "name": "", "value": "customers:manage" },
    { "name": "", "value": "customers:create" },
    { "name": "", "value": "customers:update" },
    { "name": "", "value": "customers:import" },
    { "name": "", "value": "customers:delete" },

    { "name": "", "value": "debts:*" },
    { "name": "", "value": "debts:manage" },
    { "name": "", "value": "debts:initiate" },
    { "name": "", "value": "debts:schedule" },
    { "name": "", "value": "debts:cancel" },

    { "name": "", "value": "regions:*" },
    { "name": "", "value": "regions:manage" },
    { "name": "", "value": "regions:create" },
    { "name": "", "value": "regions:update" },
    { "name": "", "value": "regions:import" },
    { "name": "", "value": "regions:delete" },

    { "name": "", "value": "districts:*" },
    { "name": "", "value": "districts:manage" },
    { "name": "", "value": "districts:create" },
    { "name": "", "value": "districts:update" },
    { "name": "", "value": "districts:import" },
    { "name": "", "value": "districts:delete" },

    { "name": "", "value": "blocks:*" },
    { "name": "", "value": "blocks:manage" },
    { "name": "", "value": "blocks:create" },
    { "name": "", "value": "blocks:update" },
    { "name": "", "value": "blocks:import" },
    { "name": "", "value": "blocks:delete" },

    { "name": "", "value": "rounds:*" },
    { "name": "", "value": "rounds:manage" },
    { "name": "", "value": "rounds:create" },
    { "name": "", "value": "rounds:update" },
    { "name": "", "value": "rounds:import" },
    { "name": "", "value": "rounds:delete" },

    { "name": "", "value": "plots:*" },
    { "name": "", "value": "plots:manage" },
    { "name": "", "value": "plots:create" },
    { "name": "", "value": "plots:update" },
    { "name": "", "value": "plots:import" },
    { "name": "", "value": "plots:delete" },

    { "name": "", "value": "itineraries:*" },
    { "name": "", "value": "itineraries:manage" },
    { "name": "", "value": "itineraries:create" },
    { "name": "", "value": "itineraries:update" },
    { "name": "", "value": "itineraries:import" },
    { "name": "", "value": "itineraries:delete" },
    { "name": "", "value": "itineraries:configure" },

    { "name": "", "value": "properties:*" },
    { "name": "", "value": "properties:manage" },
    { "name": "", "value": "properties:create" },
    { "name": "", "value": "properties:update" },
    { "name": "", "value": "properties:import" },
    { "name": "", "value": "properties:delete" },

    { name: "", value: "service-points:*" },
    { name: "", value: "service-points:manage" },
    { name: "", value: "service-points:create" },
    { name: "", value: "service-points:update" },
    { name: "", value: "service-points:import" },
    { name: "", value: "service-points:delete" },
    { name: "", value: "service-points:update-tarrif" },
    { name: "", value: "service-points:suspend" },
    { name: "", value: "service-points:restore" },
    { name: "", value: "service-points:terminate" },
    { name: "", value: "service-points:update-location" },
    { name: "", value: "service-points:update-contact" },
    { name: "", value: "service-points:update-identity" },

    { "name": "", "value": "readings:*" },
    { "name": "", "value": "readings:manage" },
    { "name": "", "value": "readings:create" },
    { "name": "", "value": "readings:update" },
    { "name": "", "value": "readings:import" },
    { "name": "", "value": "readings:delete" },
    { "name": "", "value": "readings:schedule" },
    { "name": "", "value": "readings:assign" },
    { "name": "", "value": "readings:upload" },

    { "name": "", "value": "reading-sets:*" },
    { "name": "", "value": "reading-sets:manage" },
    { "name": "", "value": "reading-sets:create" },
    { "name": "", "value": "reading-sets:update" },
    { "name": "", "value": "reading-sets:import" },
    { "name": "", "value": "reading-sets:delete" },
    { "name": "", "value": "reading-sets:schedule" },
    { "name": "", "value": "reading-sets:assign" },

    { "name": "", "value": "reading-anomalies:*" },
    { "name": "", "value": "reading-anomalies:manage" },
    { "name": "", "value": "reading-anomalies:create" },
    { "name": "", "value": "reading-anomalies:update" },
    { "name": "", "value": "reading-anomalies:import" },
    { "name": "", "value": "reading-anomalies:delete" },
    { "name": "", "value": "reading-anomalies:approve" },
    { "name": "", "value": "reading-anomalies:assign" },

    { "name": "", "value": "reading-exceptions:*" },
    { "name": "", "value": "reading-exceptions:manage" },
    { "name": "", "value": "reading-exceptions:create" },
    { "name": "", "value": "reading-exceptions:update" },
    { "name": "", "value": "reading-exceptions:import" },
    { "name": "", "value": "reading-exceptions:delete" },
    { "name": "", "value": "reading-exceptions:approve" },
    { "name": "", "value": "reading-exceptions:assign" },

    { "name": "", "value": "reading-followups:*" },
    { "name": "", "value": "reading-followups:manage" },
    { "name": "", "value": "reading-followups:create" },
    { "name": "", "value": "reading-followups:update" },
    { "name": "", "value": "reading-followups:import" },
    { "name": "", "value": "reading-followups:delete" },
    { "name": "", "value": "reading-followups:approve" },
    { "name": "", "value": "reading-followups:assign" },

    { "name": "", "value": "unlisted-meters:*" },
    { "name": "", "value": "unlisted-meters:manage" },
    { "name": "", "value": "unlisted-meters:create" },
    { "name": "", "value": "unlisted-meters:update" },
    { "name": "", "value": "unlisted-meters:import" },
    { "name": "", "value": "unlisted-meters:delete" },
    { "name": "", "value": "unlisted-meters:approve" },
    { "name": "", "value": "unlisted-meters:assign" },

    { "name": "", "value": "tariff-classes:*" },
    { "name": "", "value": "tariff-classes:manage" },
    { "name": "", "value": "tariff-classes:create" },
    { "name": "", "value": "tariff-classes:update" },
    { "name": "", "value": "tariff-classes:import" },
    { "name": "", "value": "tariff-classes:delete" },

    { "name": "", "value": "tariff-instances:*" },
    { "name": "", "value": "tariff-instances:manage" },
    { "name": "", "value": "tariff-instances:create" },
    { "name": "", "value": "tariff-instances:update" },
    { "name": "", "value": "tariff-instances:import" },
    { "name": "", "value": "tariff-instances:delete" },
    { "name": "", "value": "tariff-instances:configure" },

    { "name": "", "value": "reconnection-service-orders:*" },
    { "name": "", "value": "reconnection-service-orders:manage" },
    { "name": "", "value": "reconnection-service-orders:create" },
    { "name": "", "value": "reconnection-service-orders:update" },
    { "name": "", "value": "reconnection-service-orders:import" },
    { "name": "", "value": "reconnection-service-orders:delete" },

    { "name": "", "value": "disconnection-service-orders:*" },
    { "name": "", "value": "disconnection-service-orders:manage" },
    { "name": "", "value": "disconnection-service-orders:create" },
    { "name": "", "value": "disconnection-service-orders:update" },
    { "name": "", "value": "disconnection-service-orders:import" },
    { "name": "", "value": "disconnection-service-orders:delete" },

    { "name": "", "value": "readers:*" },
    { "name": "", "value": "readers:manage" },
    { "name": "", "value": "readers:create" },
    { "name": "", "value": "readers:update" },
    { "name": "", "value": "readers:import" },
    { "name": "", "value": "readers:delete" },

    { "name": "", "value": "staff:*" },
    { "name": "", "value": "staff:manage" },
    { "name": "", "value": "staff:create" },
    { "name": "", "value": "staff:update" },
    { "name": "", "value": "staff:import" },
    { "name": "", "value": "staff:delete" },

    { "name": "", "value": "bills:*" },
    { "name": "", "value": "bills:manage" },
    { "name": "", "value": "bills:create" },
    { "name": "", "value": "bills:update" },
    { "name": "", "value": "bills:import" },
    { "name": "", "value": "bills:delete" },
    { "name": "", "value": "bills:send" },
    { "name": "", "value": "bills:print" },
    { "name": "", "value": "bills:approve" },
    { "name": "", "value": "bills:schedule" },

    { "name": "", "value": "payments:*" },
    { "name": "", "value": "payments:manage" },
    { "name": "", "value": "payments:create" },
    { "name": "", "value": "payments:update" },
    { "name": "", "value": "payments:import" },
    { "name": "", "value": "payments:delete" },

    { "name": "", "value": "debtors:*" },
    { "name": "", "value": "debtors:manage" },
    { "name": "", "value": "debtors:create" },
    { "name": "", "value": "debtors:update" },
    { "name": "", "value": "debtors:import" },
    { "name": "", "value": "debtors:delete" },
] as const;