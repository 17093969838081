import lodash from "lodash";
import moment from "moment";
import * as Yup from "yup";

export const CustomerInfoFormSchema = Yup.object().shape({
  existingServicePoint: Yup.object().required("Kindly select a customer"),
});
export const DebtInfoFormSchema = Yup.object().shape({
  amount: Yup.number().min(0.01, "Must be more than 0.01").required("Debt amount is required."),
  sendWholeDebt: Yup.boolean().required(),
  description: Yup.string().required("Debt description is required."),
  installments: Yup.array()
    .of(
      Yup.object({
        amount: Yup.number().required("Installment Amount Is Required"),
        dueDate: Yup.date()
          .min(new Date(), ({min}) => `Installment Due Date Should Be Greater Than ${moment(min).format("DD/MM/YYYY")}`)
          .test(
            "dueDate",
            "Installment Due Date Should Be Less Than Previous Installment",
            function (current, { options }: any) {
            if (options?.index > 0) {
              const previous =
                options?.from?.[1]?.value?.installments?.[options?.index - 1];
              return moment(current).isAfter(moment(previous?.dueDate));
            }
            return true;
          })
          .required("Installment Due Date Is Required"),
      })
    )
    .test("sum", "Installments do not sum up to Debt Amount",function (current, {parent}) {
      console.log({parent})
      const totalAmount = lodash.sumBy(current, "amount");
      return totalAmount === parent.amount;
    }),
});

export const ChargesInfoFormSchema = Yup.object().shape({});

export const InitiateDebtForm = Yup.object().shape({
  customerInfo: CustomerInfoFormSchema,
  debtInfo: DebtInfoFormSchema,
});
