import { gql, useMutation, useQuery } from "@apollo/client";
import { SubmitFinalBalanceForm, Modal } from "components";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { GET_REPLACEMENT_SERVICE_ORDER } from "./view";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import moment from "moment";
import numeral from "numeral";
import * as yup from "yup";
import { SubmitFinalBalanceSchema } from "components/forms/submit-final-balance/schema";

const INITIATE_DEBT = gql`
  mutation InitiateReplacementServiceOrderBalance(
    $id: ID!
    $amount: Float
    $finalReadingDate: Date
    $isCredit: Boolean
    $previousPurchases: [DebtRecoveryPreviousPurchasesInput]
  ) {
    initiateReplacementServiceOrderBalance(
      id: $id
      amount: $amount
      finalReadingDate: $finalReadingDate
      isCredit: $isCredit
      previousPurchases: $previousPurchases
    ) {
      _id
    }
  }
`;

export default function SubmitFinalBalanceContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [initiateDebt] = useMutation(INITIATE_DEBT);
  const { data, loading } = useQuery(GET_REPLACEMENT_SERVICE_ORDER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
    onCompleted: (data) => {
      if (data?.replacement?._id) {
        form.setFieldValue(
          "recoveryInfo.finalReadingDate",
          moment(
            data?.replacement?.resolution?.previousReading?.readingDate
          ).format("YYYY-MM-DD")
        );
      }
    },
  });

  const form = useFormik({
    initialValues: {
      recoveryInfo: {
        amount: 0,
        enterAmountManually: false,
        finalReadingDate: "",
        previousPurchases: [
          {
            date: "",
            amount: 0,
          },
          {
            date: "",
            amount: 0,
          },
          {
            date: "",
            amount: 0,
          },
        ] as any[],
      },
      chargesInfo: {},
    },
    validationSchema: SubmitFinalBalanceSchema,
    onSubmit: async (values) => {
      await initiateDebt({
        variables: {
          id: searchParams.id,
          ...values.recoveryInfo,
          amount: numeral(
            Math.abs(values.recoveryInfo.amount).toFixed(2)
          ).value(),
          isCredit: values.recoveryInfo.amount >= 0 ? true : false,
          previousPurchases: values.recoveryInfo?.enterAmountManually
            ? values.recoveryInfo.previousPurchases
            : [],
        },
      }).then(({ data }) => {
        if (data.initiateReplacementServiceOrderBalance._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Balance Submitted Successfully",
            })
          );
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could Not Initiate Debt",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
      refetch?.();
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title='Submit Final Balance'
      description='Provide details to submit final balance'
      size='5xl'
      hideActions={true}
      loading={loading}
    >
      {data?.replacement?._id && (
        <SubmitFinalBalanceForm
          form={form}
          servicePoint={data?.replacement?.servicePoint}
          replacement={data?.replacement}
        />
      )}
    </Modal>
  );
}
