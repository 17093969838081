import { gql, useMutation } from "@apollo/client";
import { CreateDebtForm, Modal } from "components";
import { useFormik } from "formik";
import moment from "moment";
import numeral from "numeral";
import toast from "react-hot-toast";
import { useNavigate } from "react-location";
import { LocationGenerics } from "router/location";

const INITIATE_DEBT = gql`
  mutation InitiateDebt(
    $servicePoint: ID!
    $amount: PositiveFloat!
    $description: String!
    $type: DebtType!
    $installments: [DebtInstallmentInput!]!
  ) {
    debt: initiateDebt(
      servicePoint: $servicePoint
      amount: $amount
      type: $type
      description: $description
      installments: $installments
    ) {
      _id
    }
  }
`;

export default function SubmitFinalBalanceContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const navigate = useNavigate<LocationGenerics>();
  const [initiateDebt] = useMutation(INITIATE_DEBT);

  const form = useFormik({
    initialValues: {
      customerInfo: {
        existingServicePoint: null as any,
      },
      debtInfo: {
        sendWholeDebt: true,
        installments: [] as any[],
        amount: 0,
        description: "",
        type: "DebtRecovery",
      },
    },
    onSubmit: async (values) => {
      await initiateDebt({
        variables: {
          servicePoint: values?.customerInfo?.existingServicePoint?._id,
          amount: values.debtInfo.amount,
          description: values.debtInfo.description,
          type: values.debtInfo.type,
          installments: values.debtInfo.sendWholeDebt
            ? [
              {
                description: "Whole Installment",
                amount: values.debtInfo.amount,
                dueDate: moment(),
              },
            ]
            : values.debtInfo.installments.map((installment, idx) => ({
              ...installment,
              description: `${numeral(idx + 1).format(
                "0o"
              )} Installment due ${moment(installment.dueDate).format(
                "DD/MM/YYYY"
              )}`,
            })),
        },
      }).then(({ data }) => {
        if (data.debt._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Debt Initiated Successfully",
            })
          );
          navigate({
            search: (old) => ({
              id: data.debt._id,
              modal: "schedule",
            }),
          });
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could Not Initiate Debt",
            })
          );
        }
      });
    },
    onReset: () => {
      refetch?.();
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Initiate Debt"
      description="Provide details to initiate debt"
      size="5xl"
      hideActions={true}
    >
      <CreateDebtForm form={form} />
    </Modal>
  );
}
