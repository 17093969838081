import { gql, useMutation, useQuery } from "@apollo/client";
import { Modal, ScheduleDebtForm } from "components";
import { useFormik } from "formik";
import { LocationGenerics } from "router/location";
import { useSearch } from "react-location";
import { useEffect } from "react";
import moment from "moment";
import toast from "react-hot-toast";
import numeral from "numeral";
import { GET_DEBT } from "./view";
import { ScheduleDebtFormSchema } from "components/forms/schedule-debt/schema";

const SCHEDULE_DEBT = gql`
  mutation ScheduleDebt($id: ID!, $installments: [DebtInstallmentInput!]!) {
    debt: scheduleDebt(id: $id, installments: $installments) {
      _id
    }
  }
`;

export default function ScheduleDebtContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [scheduleDebt] = useMutation(SCHEDULE_DEBT);
  const { data, loading } = useQuery(GET_DEBT, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const form = useFormik({
    initialValues: {
      installmentInfo: {
        sendWholeDebt: true,
        installments: [] as any[],
      },
    },
    validationSchema: ScheduleDebtFormSchema(data?.debt?.amount),
    onSubmit: async (values) => {
      console.log("tripping", values);

      await scheduleDebt({
        variables: {
          id: searchParams.id,
          installments: values.installmentInfo.sendWholeDebt
            ? [
              {
                description: "Whole Installment",
                amount: data?.debt?.amount,
                dueDate: moment(),
              },
            ]
            : values.installmentInfo.installments.map((installment, idx) => ({
              ...installment,
              description: `${numeral(idx + 1).format(
                "0o"
              )} Installment due ${moment(installment.dueDate).format(
                "DD/MM/YYYY"
              )}`,
            })),
        },
      }).then(({ data }) => {
        if (data.debt._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Debt scheduled Successfully",
            })
          );
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not schedule debt",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (data?.debt?._id) {
      form.setValues({
        installmentInfo: {
          sendWholeDebt: false,
          installments: [
            {
              amount: data?.debt?.amount,
              dueDate: moment().add(1, "day").format("DD-MM-YYYY"),
            },
          ],
        },
      });
    }
  }, [data?.debt, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Schedule Debt"
      description="Details of debt are shown below"
      size="5xl"
      hideActions={true}
    >
      {data?.debt?._id && <ScheduleDebtForm debt={data?.debt} form={form} />}
    </Modal>
  );
}
