import moment from "moment";
import * as Yup from "yup";

export const ChargesInfoFormSchema = Yup.object().shape({});

export const RecoveryInfoFormSchema = Yup.object({
  amount: Yup.number().required("Kindly enter the meter balance"),
  enterAmountManually: Yup.boolean(),
  finalReadingDate: Yup
    .string()
    .required("Kindly enter the final reading date"),
  previousPurchases: Yup.array().when("enterAmountManually", {
    is: true,
    then: Yup.array().of(
      Yup.object({
        date: Yup.string().test({
          name: 'isGreaterThanFinalReadingDate',
          test: (val, ctx) => {
            return moment(val).isBefore(moment(ctx.parent.finalReadingDate))
          },
          message: "Date must be before or on meter removal date"
        }).required("Kindly enter the purchase date"),
        amount: Yup.number().min(1, "Purchase amount should be 1 cedi or more").required("Kindly enter the purchase amount"),
      })
    ).min(1, "Kindly enter at least one previous purchase"),
    otherwise: Yup.array(),
  }),
})

export const SubmitFinalBalanceSchema = Yup.object({
  recoveryInfo: RecoveryInfoFormSchema,
  chargesInfo: ChargesInfoFormSchema,
});
