import { TextArea, TextInput } from "components"
import { FormikProps } from "formik";
import { FC } from "react";


type Props = {
    form: FormikProps<any>;
};

const CompleteCreditForm: FC<Props> = ({ form }) => {
    return (
        <div className="space-y-6 divide-y no-scrollbar divide-gray-200 flex-1 overflow-y-auto">
            <div className=''>
                {/* <span className="text-xs font-light">Resolution Details</span> */}
                <div className="grid grid-cols-2 gap-6 mt-2">
                    <div className='col-span-2'>
                        <TextInput
                            id="token"
                            label="Token"
                            placeholder="e.g. 123456"
                            {...form} />
                    </div>
                    <div className='col-span-2'>
                        <TextArea
                            id="notes"
                            label="Notes"
                            placeholder=""
                            {...form} />
                    </div>
                 
                </div>
            </div>
        </div>
    )
}

export default CompleteCreditForm;