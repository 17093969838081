import { FC } from "react";
import moment from "moment";
import { classNames } from "utils";
import {
  PlusCircleIcon,
  CalendarDaysIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import numeral from "numeral";

interface ScheduleHistoryViewProps {
  history: {
    actorType: "User";
    actor: any;
    data: any[];
    timestamp: string;
    notes: string;
    action: "Initiate" | "Schedule" | "Reschedule" | "Cancel";
  }[];
}

const resolveHistoryActor = (
  type: any,
  details: { name: any; firstName: any; lastName: any; username: any }
) => {
  switch (type) {
    case "User": {
      return [details?.firstName, details?.lastName].join(" ");
    }
    default:
      return "N/A";
  }
};

const ScheduleHistoryView: FC<ScheduleHistoryViewProps> = ({ history }) => {
  const actionIconMap = {
    Initiate: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <PlusCircleIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    Schedule: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <CalendarDaysIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    Reschedule: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <CalendarDaysIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    Cancel: (
      <span
        className={classNames(
          "bg-red-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
  };

  const renderActionDetails = (
    action: ScheduleHistoryViewProps["history"][number]
  ) => {
    switch (action.action) {
      case "Initiate": {
        return (
          <p className="text-sm text-gray-500">
            <span className="font-medium text-gray-900">
              {resolveHistoryActor(action.actorType, action.actor)}
            </span>{" "}
            initiated debt
          </p>
        );
      }
      case "Schedule":
      case "Reschedule": {
        return (
          <>
            <p className="text-sm text-gray-500">
              <span className="font-medium text-gray-900">
                {resolveHistoryActor(action.actorType, action.actor)}
              </span>{" "}
              scheduled debt
              <span className="font-medium text-gray-900"></span>
            </p>
            {action?.data && (
              <div className="relative p-4 py-2 border border-gray-300 rounded mt-6">
                <span className="absolute bg-white px-2 -top-2 text-xs">
                  Schedule
                </span>
                <ul className="divide-y divide-gray-200">
                  {action?.data?.map((installment, index) => (
                    <li
                      key={installment.code}
                      className="flex justify-between gap-x-6 py-3"
                    >
                      <div className="flex min-w-0 gap-x-4">
                        <p className="text-sm font-semibold leading-6 text-gray-700">
                          {index + 1}.
                        </p>
                        <div className="min-w-0 flex-auto">
                          <p className="text-sm font-semibold leading-6 text-gray-700">
                            {installment.code}
                          </p>
                          <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                            {installment.description}
                          </p>
                        </div>
                      </div>
                      <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                        <p className="text-sm leading-6 text-gray-900">
                          GHS {numeral(installment.amount).format("0,0.00")}
                        </p>
                        <p className="mt-1 text-xs leading-5 text-gray-500">
                          Due Date:{" "}
                          {moment(installment.dueDate).format("DD/MM/YYYY")}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {action?.notes && (
              <div className="relative p-4 border border-gray-300 rounded mt-6">
                <span className="absolute bg-white px-2 -top-2 text-xs">
                  Notes
                </span>
                <p className="text-sm text-gray-600 font-light">
                  {action.notes}
                </p>
              </div>
            )}
          </>
        );
      }
      case "Cancel": {
        return (
          <p className="text-sm text-gray-500">
            <span className="font-medium text-gray-900">
              {resolveHistoryActor(action.actorType, action.actor)}
            </span>{" "}
            cancelled debt
          </p>
        );
      }

      default:
        return null;
    }
  };

  if (!history?.length) {
    return (
      <div
        className={classNames(
          false
            ? "border-amber-400 bg-amber-50 dark:border-amber-600"
            : "border-gray-300 dark:border-gray-600",
          "flex-1 flex flex-col items-center justify-center h-full border-2 border-dashed  rounded-md text-center text-sm"
        )}
      >
        <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-gray-100">
          No Schedule History
        </h3>
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          No schedules have been created for this debt.
        </p>
      </div>
    );
  }

  return (
    <div className="flow-root">
      <ul className="-mb-8">
        {history?.map((historyItem, historyItemIdx) => (
          <li key={historyItemIdx}>
            <div className="relative pb-8">
              {historyItemIdx !== history.length - 1 ? (
                <span
                  className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex space-x-3">
                <div>{actionIconMap[historyItem.action]}</div>
                {/* {JSON.stringify(historyItem, null, 2)} */}
                <div className="flex min-w-0 flex-1 justify-between space-x-12 pt-1.5">
                  <div className="flex-1">
                    {renderActionDetails(historyItem)}
                  </div>
                  <div className="whitespace-nowrap text-right text-sm flex flex-col text-gray-500">
                    <time dateTime={historyItem.timestamp}>
                      {moment(historyItem.timestamp).format("DD/MM/YYYY")}
                    </time>
                    <time dateTime={historyItem.timestamp}>
                      {moment(historyItem.timestamp).format("hh:mm A")}
                    </time>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ScheduleHistoryView;
