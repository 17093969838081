import { gql, useQuery } from "@apollo/client";
import { DebtView, Modal } from "components";
import BalanceView from "components/cards/balance-view";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";

export const GET_BALANCE = gql`
  query GetBalance($id: ID!) {
    balance: getBalance(id: $id) {
      _id
      code
      customerCode
      accountCode
      meterCode
      prepaymentCreditStatus
      completedAt
      completedNotes
      completedBy {
        _id
        code
        firstName
        lastName
        emailAddress
        phoneNumber
      }
      completedToken
      meter {
        _id
        code
        modelMeta {
          brandCode
          brandName
          modelCode
          modelName
          phase
        }
      }
      servicePointCode
      servicePoint {
        _id
        code
        serviceClass
        accountCode
        geoCode
        status
        meterCode
        tariffClass {
          _id
          code
          name
        }
        region {
          _id
          code
          name
        }
        district {
          _id
          code
          name
        }
        block {
          _id
          code
          name
        }
        round {
          _id
          code
          name
        }
        plot {
          _id
          code
          name
        }
        customerCode
        status
        customer {
          code
          customerType

          organization {
            name
          }
          representative {
            fullName
            profileImageUrl
            phoneNumber
            emailAddress
          }
          status
        }
      }
      data {
        finalReadingDate
        previousPurchases {
          amount
          date
        }
      }
      amount
      type
      scheduledAt
      createdAt
      updatedAt
      customer {
        code
        customerType
        representative {
          fullName
          phoneNumber
          profileImageUrl
          ghanaCardNumber
        }
        organization {
          name
        }
      }
      createdBy {
        _id
        code
        firstName
        lastName
        emailAddress
        phoneNumber
      }
      
    }
  }
`;

export default function ViewDebtContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_BALANCE, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title='Balance Information'
      description='Details of balance are shown below'
      size='5xl'
      // renderActions={() => (
      //   <>
      //     {/* {withPermissions(["debts:*", "debts:send"])( */}
      //     <button
      //       type="button"
      //       className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
      //       onClick={wrapClick(sendItem)}
      //     >
      //       Send
      //     </button>
      //     {/* )} */}
      //     {/* {withPermissions(["debts:*", "debts:print"])( */}
      //     <button
      //       type="button"
      //       className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
      //       onClick={wrapClick(handlePrint)}
      //     >
      //       Print
      //     </button>
      //     {/* )} */}
      //   </>
      // )}
    >
      <BalanceView balance={data?.balance} />
    </Modal>
  );
}
