import { classNames, withPermissions, wrapClick } from "utils";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  ReadingHistoryView,
  ServicePointView,
  Modal,
  ReplacementResolutionView,
} from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { useState } from "react";
import { Action } from "components/buttons/action-button";
import MeterView from "components/cards/meter-view";

export const GET_REPLACEMENT_SERVICE_ORDER = gql`
  query GetReplacementServiceOrder($id: ID!) {
    replacement: getReplacementServiceOrder(id: $id) {
      _id
      oldMeterNumber
      oldMeterPhase
      resolvedAt
      debtStatus
      installationMeter {
        code
        modelType
        modelMeta {
          phase
        }
      }
      servicePoint {
        _id
        code
        serviceClass
        accountCode
        geoCode
        status
        meterCode
        serviceType
        contractedDemand
        meterLocation
        meta {
          lastReadingDate
          lastReadingValue
          lastBillValue
        }
        tariffClass {
          _id
          code
          name
        }
        regionCode
        regionName
        districtCode
        districtName
        blockName
        roundName
        roundCode
        plotCode
        propertyCode
        property {
          blockName
          roundName
          plotName
          itineraryCode
        }
        accountData {
          debtAmount
          lastBillAmount  
        }
        customerRepresentative {
          emailAddress
          ghanaCardNumber
        }
        meter {
          modelType
          code
          status
          modelMeta {
            brandCode
            brandName
            modelCode
            modelName
            phase
          }
        }
        customerCode
        status
        customer {
          code
          customerType

          organization {
            name
          }
          representative {
            fullName
            profileImageUrl
            phoneNumber
            emailAddress
          }
          status
        }
      }
      resolution {
        previousReading {
          readingDate
          readingImageUrl
          readingValue
        }
        recoveryData {
          avgMonthlyConsumption
          creditAmount
          isCustomerDamage
          debtAmount
          numberOfMonths
          creditAmount
        }
      }
      meter {
        _id
        code
        modelType
        status
        modelMeta {
          modelCode
          modelName
          brandCode
          brandName
          systemCode
          systemName
          phase
          digits
        }
      }
      completedAt
      code
      oldMeterNumber
      oldMeterPhase
      replacementReason
      replacementType
    }
  }
`;

const GET_READING_HISTORY = gql`
  query GetCustomerReadingHistory($servicePoint: ID) {
    readings: getReadings(
      statuses: [MeterRead, ExceptionDetected]
      servicePoint: $servicePoint
      sort: "year month"
    ) {
      _id
      currentReading {
        readingDate
        readingValue
        readingEstimated
        consumptionValue
        billValue
        totalAmountDue
      }
      previousReading {
        readingDate
        readingValue
        readingEstimated
        consumptionValue
        billValue
        totalAmountDue
      }
      status
      meta {
        readingImageUrl
        readingLocation {
          coordinates
          type
        }
      }
    }
  }
`;

const tabs = [
  { name: "Customer Details", href: "details" },
  {
    name: "Resolution Details",
    href: "resolution",
  },
  {
    name: "Reading History Details",
    href: "history",
  },
  {
    name: "Meter Details",
    href: "meter",
  },
];

export default function ViewCustomerReadingContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [orderTab, setOrderTab] = useState("resolution");
  const [getReadingHistory, { data: dataHistory, loading: loadingHistory }] =
    useLazyQuery(GET_READING_HISTORY, {
      notifyOnNetworkStatusChange: false,
    });
  const { data, loading } = useQuery(GET_REPLACEMENT_SERVICE_ORDER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
    onCompleted(data) {
      console.log("something is", data);
      if (data?.replacement?.servicePoint?._id) {
        getReadingHistory({
          variables: {
            servicePoint: data?.replacement?.servicePoint?._id,
          },
        });
      }
    },
  });

  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };
  const dispatchAction =
    (action: Exclude<Action, "expand" | "goto" | "clone">) => () => {
      navigate({
        search: (old) => ({
          ...old,
          id: searchParams.id,
          modal: action,
        }),
      });
    };

  return (
    <Modal
      open={open}
      loading={loading}
      setOpen={setOpen}
      hidePadding
      title='Replacement Information'
      description='Details of replacement are shown below'
      size='5xl'
      // renderActions={
      //   data?.replacement?.debtStatus &&
      //   data?.replacement?.debtStatus == "ReadingPending"
      //     ? () => (
      //         <>
      //           <button
      //             type='button'
      //             className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
      //             onClick={wrapClick(dispatchAction("submit"))}
      //           >
      //             Submit Final Reading
      //           </button>
      //         </>
      //       )
      //     : undefined
      // }
    >
      <div className='block'>
        <div className='border-b border-gray-200 bg-white px-6'>
          <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
            {tabs?.map((_status) => (
              <button
                key={_status.name}
                onClick={wrapClick(__setOrderTab(_status.href))}
                className={classNames(
                  orderTab === _status.href
                    ? "border-primary-500 text-primary-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={orderTab === _status.href ? "page" : undefined}
              >
                {_status.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div className='flex-1 w-full min-h-[65vh] max-h-[65vh] overflow-y-auto  sm:p-6'>
        {orderTab === "details" && (
          <ServicePointView servicePoint={data?.replacement?.servicePoint} />
        )}
        {orderTab === "resolution" && (
          <ReplacementResolutionView
            resolution={ { ...data?.replacement?.resolution, meter:{ ...data?.replacement.meter } } }
          />
        )}
        {orderTab === "history" && (
          <ReadingHistoryView
            readings={dataHistory?.readings}
            loading={loadingHistory}
          />
        )}
        {orderTab === "meter" && (
          <MeterView data={data?.replacement} loading={loading} />
        )}
      </div>
    </Modal>
  );
}
