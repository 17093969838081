import { gql, useQuery } from "@apollo/client";
import { Modal, PlotView } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";

export const GET_PLOT = gql`
  query GetPlot($id: ID!) {
    plot: getPlot(id: $id) {
      _id
      code
      name
      region {
        _id
      }
      regionCode
      regionName
      district {
        _id
      }
      districtCode
      districtName
      block {
        _id
      }
      blockCode
      blockName
      round {
        _id
      }
      roundCode
      roundName
      meta {
        itinerariesCount
        propertiesCount
        customersCount
      }
      debtMeta {
        totalDebtors
        totalDebtAmount
        lowestDebtAmount
        highestDebtAmount
      }
      prioritizedDebtMeta {
        totalDebtors
        totalDebtAmount
        lowestDebtAmount
        highestDebtAmount
      }
      disconnector {
        _id
        code
        lastName
        firstName
        profileImageUrl
        phoneNumber
        emailAddress
      }
      assignedStaff {
        team {
          _id
          code
          name
        }
        disconnector {
          _id
          code
          lastName
          firstName
          profileImageUrl
          phoneNumber
          emailAddress
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export default function ViewPlotContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_PLOT, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Debtor Set Information"
      loading={loading}
      description="Details of debtor set are shown below"
    >
      <PlotView plot={data?.plot} from={"debt"} />
    </Modal>
  );
}
