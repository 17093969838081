import { useFormik } from "formik";
import { FC } from "react";
import { wrapClick } from "utils";
import { ChargesInfoFormSchema } from "./schema";
import numeral from "numeral";
import _ from "lodash";

interface ChargesInfoFormProps {
  handleNext: (values: any) => void;
  handlePrevious: () => void;
  initialValues: any;
  values: any;
  handleCancel: () => void;
  servicePoint: any;
  replacement: any;
  handleSubmit: () => void;
  submitLoading: boolean;
}

const ChargesInfoForm: FC<ChargesInfoFormProps> = ({
  initialValues,
  handleSubmit,
  handlePrevious,
  handleCancel,
  values: parentValues,
  servicePoint,
  submitLoading,
}) => {
  const form = useFormik({
    initialValues,
    validationSchema: ChargesInfoFormSchema,
    onSubmit: () => {
      handleSubmit();
    },
    onReset: () => {
      handleCancel?.();
    },
  });


  return (
    <div className='flex-1 flex flex-col overflow-hidden'>
      <div className='space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto'>
        <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3'>
          <div>
            <div className='grid grid-cols-4 gap-6 col-span-1'>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Amount
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  GHS {numeral(Math.abs(parentValues.recoveryInfo?.amount)).format("0,0.00") || "N/A"} {(parentValues.recoveryInfo?.amount > 0
                    ? " (Credit)"
                    : parentValues.recoveryInfo?.amount < 0
                    ? " (Debt)"
                    : "")}


                </div>
              </div>
              <div className='col-span-3'>
                <span className='block text-sm font-light text-gray-700'>
                  Replacement Date
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {parentValues.recoveryInfo?.finalReadingDate || "N/A"}
                </div>
              </div>
            </div>
          </div>
        </div>
        {parentValues?.recoveryInfo?.enterAmountManually && parentValues?.recoveryInfo?.previousPurchases?.length > 0 && (
          <div className=''>
            <div className='grid grid-cols-3 gap-6 mt-2'>
              <div className='col-span-3'>
                <span className='block text-sm font-medium text-gray-700'>
                  Previous Purchases
                </span>
                <div className='mt-1 w-full '>
                  <table className='min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md'>
                    <thead className='bg-gray-50'>
                      <tr className='divide-x divide-gray-200'>
                        <th
                          scope='col'
                          className='whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900  text-left'
                        >
                          Date
                        </th>
                        <th
                          scope='col'
                          className='whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900  text-right w-96'
                        >
                          Amount
                        </th>

                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-white'>
                      {parentValues?.recoveryInfo.previousPurchases?.map((item: any, idx: number) => (
                        <tr key={idx} className="divide-x divide-gray-200">
                          <td className="whitespace- px-2 py-2 text-sm text-gray-700 text-ellipsis overflow-hidden ">{item.date}</td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{item.amount ? `GHS ${item.amount}` : "N/A"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
        )}
      </div>

      <div className='bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200'>
        <button
          type='button'
          onClick={wrapClick(form.handleSubmit)}
          disabled={submitLoading}
          className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
        >
          {submitLoading ? "Submitting..." : "Submit"}
        </button>
        <button
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ChargesInfoForm;
