import { gql, useQuery } from "@apollo/client";
import { ServicePointView, Modal, CustomerDebtView } from "components";
import { useState } from "react";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { classNames, wrapClick } from "utils";

const GET_SERVICE_POINT = gql`
  query GetServicePoint($id: ID!) {
    servicePoint: getServicePoint(id: $id) {
      _id
      code
      qrCode
      geoLocation {
        type
        coordinates
      }
      regionCode
      regionName
      districtCode
      districtName
      blockName
      roundName
      roundCode
      plotCode
      itinerary {
        _id
        code
      }
      propertyCode
      property {
        _id
        code
        qrCode
        address
        ghanaPostAddress
        streetName
        houseNumber
        houseNumberSlateUrl
        closestLandmark
        structureNumber
        sitePlanDocumentUrl
        owner {
          fullName
          phoneNumber
        }
        premiseType {
          _id
          code
          name
        }
        premiseCategory {
          _id
          code
          name
        }
        blockName
        roundName
        plotName
        itineraryCode
        community
      }
      customer {
        _id
        code
        customerType

        organization {
          name
          taxIdentificationNumber
          organizationRegistrationNumber
          organizationRegistrationDate
          organizationRegistrationDocumentUrl
          certificateOfIncorporationDocumentUrl
        }
        representative {
          title
          fullName
          nationality
          dateOfBirth
          gender
          phoneNumber
          emailAddress
          profileImageUrl
          hasGhanaCard
          ghanaCardNumber
          ghanaCardIssueDate
          ghanaCardExpiryDate
          ghanaCardFrontImageUrl
          ghanaCardBackImageUrl
          identityCardType
          identityCardNumber
          identityCardIssueDate
          identityCardExpiryDate
          identityCardFrontImageUrl
          identityCardBackImageUrl
        }
        status
        createdAt
        updatedAt
      }
      account {
        _id
        code
        status
        balance
        balanceUpdatedAt
        meta {
          lastPaymentAmount
          lastPaymentDate
          lastBillAmount
          lastBillDate
          lastReadingValue
          lastReadingDate
        }
        createdAt
        updatedAt
      }
      meter {
        _id
        code
        batchCode
        system {
          _id
          code
          name
        }
        brand {
          _id
          code
          name
        }
        model {
          _id
          code
          name
          phase
          type
          digits
          voltage
          currentRating
          mechanism
          communicationType
        }
        status
        balance
        balanceUpdatedAt
        meta {
          lastReadingValue
          lastReadingDate
        }
      }
      meterCode
      serviceType
      serviceClass
      tariffClass {
        _id
        code
        name
        serviceClass
      }
      geoCode
      transformerPhase
      meterLocation
      meterHeight
      transformerNumber
      currentTransformerRatio
      deliveryPointNumber
      contractedDemand
      accountCode
      poleNumber
      energyCertificateNumber
      energyCertificateDocumentUrl
      activity {
        _id
        code
        name
      }
      subActivity {
        _id
        code
        name
      }
      appliances {
        appliance {
          _id
          code
          name
          wattage
        }
        quantity
      }
      status
      meta {
        lastBillValue
        lastBillDate
        lastReadingValue
        lastReadingDate
      }
      accountData {
        lastPaymentDate
        lastPaymentAmount
        lastBillDate
        lastBillAmount
        agingSchedule {
          billReference
          billDate
          billAmount
          debtAmount
          dueDate
        }
        currentBalance
        debtAmount
        toBeDisconnected
      }
      createdAt
      updatedAt
    }
  }
`;

const orderTabs = [
  {
    name: "Customer Details",
    href: "customerDetails",
  },
  {
    name: "Debt Information",
    href: "debtInformation",
  },
];

export default function ViewDebtCustomerContainer({
  open,
  setOpen,
  prefix = ""
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  prefix?: string
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_SERVICE_POINT, {
    variables: {
      id: (searchParams as any)?.[prefix + "id"],
    },
    notifyOnNetworkStatusChange: false,
  });

  const [orderTab, setOrderTab] = useState("customerDetails");

  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title='Service Point Information'
      loading={loading}
      hidePadding
      description='Details of service points are shown below'
    >
      <>
        <div className='block'>
          <div className='border-b border-gray-200 bg-white px-6'>
            <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
              {orderTabs.map((_orderTab) => (
                <button
                  key={_orderTab.name}
                  onClick={wrapClick(__setOrderTab(_orderTab.href))}
                  className={classNames(
                    orderTab === _orderTab.href
                      ? "border-primary-500 text-primary-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                    "whitespace-nowrap disabled:cursor-not-allowed flex py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={
                    orderTab === _orderTab.href ? "page" : undefined
                  }
                >
                  {_orderTab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
        <div className='flex-1 w-full max-h-[85vh] overflow-y-auto  sm:p-6'>
          {orderTab === "customerDetails" && (
            <ServicePointView servicePoint={data?.servicePoint} />
          )}

          {orderTab === "debtInformation" && (
            <CustomerDebtView data={data?.servicePoint?.accountData || {}} />
          )}
        </div>
      </>
    </Modal>
  );
}
