import {
  BanknotesIcon,
  BoltIcon,
  BoltSlashIcon,
  CalculatorIcon,
  ChartBarSquareIcon,
  CircleStackIcon,
  LifebuoyIcon,
  QrCodeIcon,
  ReceiptPercentIcon,
  UserCircleIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { UserPermission } from "apollo/cache/auth";
import CalendarLayout from "layouts/calendar-wrapper";
import {
  AuthPage,
  CustomersPage,
  DashboardPage,
  DebtorsCustomersPage,
  DebtorsPage,
  DebtsPage,
  DisconnectionServiceOrdersPage,
  FlatRateCustomersPage,
  ForgotPasswordPage,
  PostPaidReplacementServiceOrdersPage,
  PrepaidReplacementServiceOrdersPage,
  ReconnectionServiceOrdersPage,
  ServicePointReadingsPage,
  ServiceRegularizationsPage,
  SettingsPage,
  ShepRegularizationsPage,
  SignInPage,
} from "pages";
import BalancesPage from "pages/balances";
import CreditsPage from "pages/credits";
import { Outlet, Route } from "react-location";

export type RouteProps = Omit<Route, "children"> & {
  withPermissions?: UserPermission[];
  navigation?: boolean;
  sidebar?: { label: string; icon: any };
  children?: RouteProps[];
};

const routes: RouteProps[] = [
  // STATISTICS
  {
    path: "/",
    element: <CalendarLayout />,
    sidebar: {
      label: "Dashboard",
      icon: ChartBarSquareIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Dashboard",
      section: "Statistics",
    },
    children: [
      {
        id: "all",
        path: "/",
        element: <DashboardPage />,
        meta: {
          layout: "App",
          section: "Statistics",
        },
      },
    ],
  },

  {
    path: "service-points",
    element: <Outlet />,
    sidebar: {
      label: "Service Points",
      icon: QrCodeIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Service Points",
      section: "Customer Management",
    },
    children: [
      {
        path: "/",
        element: <CustomersPage />,
        meta: {
          layout: "App",
          section: "Customer Management",
        },
        withPermissions: ["*:*", "service-points:*", "service-points:manage"],
      },
      {
        path: ":servicePoint/readings",
        element: <ServicePointReadingsPage />,
        meta: {
          layout: "App",
          breadcrumb: () => "Service Point Readings",
          section: "Customer Management",
        },
        withPermissions: ["*:*", "service-points:*", "service-points:manage"],
      },
    ],
    withPermissions: ["*:*", "service-points:*", "service-points:manage"],
  },
  {
    path: "prepaid-replacements",
    element: <PrepaidReplacementServiceOrdersPage />,
    sidebar: {
      label: "Prepaid Replacements",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Prepaid Replacements",
      section: "Debt Administration",
    },
  },
  {
    path: "postpaid-replacements",
    element: <PostPaidReplacementServiceOrdersPage />,
    sidebar: {
      label: "Postpaid Replacements",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Postpaid Replacements",
      section: "Debt Administration",
    },
  },

  {
    path: "flat-rate-customers",
    element: <FlatRateCustomersPage />,
    sidebar: {
      label: "Flat Rate Customers",
      icon: CalculatorIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Flat Rate Customers",
      section: "Customer Management",
    },
    withPermissions: ["*:*", "service-points:*", "service-points:manage"],
  },
  {
    path: "balances",
    element: <BalancesPage />,
    sidebar: {
      label: "Balances",
      icon: BanknotesIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Balances",
      section: "Debt Administration",
    },
  },
  {
    path: "credits",
    element: <CreditsPage />,
    sidebar: {
      label: "Credits",
      icon: ReceiptPercentIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Credits",
      section: "Debt Administration",
    },
  },
  {
    path: "debts",
    element: <DebtsPage />,
    sidebar: {
      label: "Debts",
      icon: CircleStackIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Debts",
      section: "Debt Administration",
    },
  },
  // {
  //   path: "debt-dockets",
  //   element: <Outlet />,
  //   sidebar: {
  //     label: "Debt Dockets",
  //     icon: BoltSlashIcon,
  //   },
  //   meta: {
  //     layout: "App",
  //     breadcrumb: () => "Debt Dockets",
  //     section: "Debt Administration",
  //   },
  //   children: [
  //     {
  //       path: "/",
  //       element: <DebtDocketsPage />,
  //       meta: {
  //         layout: "App",
  //         section: "Debt Administration",
  //       },
  //       withPermissions: [
  //         "*:*",
  //         "debts:*",
  //         "debts:manage",
  //       ],
  //     },
  //     {
  //       path: ":plot/debt-customers/:plotCode",
  //       element: <DebtCustomersPage />,
  //       meta: {
  //         layout: "App",
  //         breadcrumb: ({ plotCode }: any) => `Debt Customers - ${plotCode}`,
  //         section: "Debt Administration",
  //       },
  //       withPermissions: [
  //         "*:*",
  //         "debts:*",
  //         "debts:manage",
  //       ],
  //     },
  //   ],
  //   withPermissions: [
  //     "*:*",
  //     "debts:*",
  //     "debts:manage",
  //   ],
  // },

  {
    path: "debtors",
    element: <DebtorsPage />,
    sidebar: {
      label: "Top Debtors",
      icon: BoltSlashIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Top Debtors",
      section: "Debt Administration",
    },
    withPermissions: [
      "*:*",
      "disconnection-service-orders:*",
      "disconnection-service-orders:manage",
    ],
  },
  {
    path: "disconnections",
    element: <DisconnectionServiceOrdersPage />,
    sidebar: {
      label: "Disconnection Orders",
      icon: BoltSlashIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Disconnection Service Orders",
      section: "Customer Management",
    },
    withPermissions: [
      "*:*",
      "disconnection-service-orders:*",
      "disconnection-service-orders:manage",
    ],
  },
  {
    path: "reconnections",
    element: <ReconnectionServiceOrdersPage />,
    sidebar: {
      label: "Reconnection Orders",
      icon: BoltIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Reconnection Service Orders",
      section: "Customer Management",
    },
    withPermissions: [
      "*:*",
      "reconnection-service-orders:*",
      "reconnection-service-orders:manage",
    ],
  },

  {
    path: "settings",
    element: <SettingsPage />,
    meta: {
      layout: "App",
      breadcrumb: () => "Settings",
      section: "Configuration",
    },
  },

  {
    path: "shep-regularizations",
    element: <CalendarLayout />,
    sidebar: {
      label: "Shep Captures",
      icon: LifebuoyIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Shep Captures",
      section: "Customer Management",
    },
    children: [
      {
        path: "/",
        element: <ShepRegularizationsPage />,
        meta: {
          layout: "App",
          section: "Customer Management",
        },
      },
    ],
    withPermissions: ["*:*", "service-points:*", "service-points:manage"],
  },
  {
    path: "service-regularizations",
    element: <CalendarLayout />,
    sidebar: {
      label: "Regularizations",
      icon: UserCircleIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Regularizations",
      section: "Customer Management",
    },
    children: [
      {
        path: "/",
        element: <ServiceRegularizationsPage />,
        meta: {
          layout: "App",
          section: "Customer Management",
        },
      },
      // {
      //   path: ":serviceRegularization",
      //   element: <ServiceRegularizationPage />,
      //   meta: {
      //     layout: "App",
      //     breadcrumb: () => "Service Regularization",
      //     section: "Faults And Complaints",
      //   },
      // },
    ],
    //TODO: Verify and enable permisions
    withPermissions: ["*:*", "service-points:*", "service-points:manage"],
  },

  // AUTH
  {
    path: "forgot-password",
    element: <ForgotPasswordPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Forgot Password",
    },
  },
  {
    path: "signin",
    element: <SignInPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Signin",
    },
  },
  {
    path: "auth",
    element: <AuthPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Auth",
    },
  },
];

export default routes;
