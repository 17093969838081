import { FC } from "react";
import moment from "moment";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { Avatar } from "components/core";
import { classNames } from "utils";
import _ from "lodash";
import numeral from "numeral";

interface BalanceViewProps {
  balance: any
}

function Owner({
  owner,
  type,
}: {
  owner: any;
  type: "field-agents" | "staff-accounts";
}) {
  return (
    <div
      className={classNames(
        "border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 hover:border-gray-400 dark:hover:border-gray-500",
        "focus:outline-none relative rounded-lg border px-3 py-3 shadow-sm flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
      )}
    >
      <div className="flex-shrink-0">
        <Avatar
          alt={
            [(owner?.lastName || "")?.trim(), (owner?.firstName || "")?.trim()]
              .join(" ")
              .trim() || "N A"
          }
          src={owner?.profileImageUrl || ""}
        />
      </div>
      <div className="flex-1 min-w-0 text-left">
        <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
          {[(owner?.lastName || "")?.trim(), (owner?.firstName || "")?.trim()]
            .join(" ")
            .trim() || "N A"}
        </p>
        <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
          {owner?.phoneNumber || "N/A"}
        </p>
      </div>
    </div>
  );
}

const BalanceView: FC<BalanceViewProps> = ({ balance }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Balance Information</span>
        <div className="grid grid-cols-4 gap-6 mt-2">
          <div>
            <span className="block text-sm font-light text-gray-700">Code</span>
            <div className="mt-1 block w-full sm:text-sm">
              {balance?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700"> Amount</span>
            <div className="mt-1 block w-full sm:text-sm">
              GHS {numeral(balance?.amount).format("0,0.00") || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">Type</span>
            <div className="mt-1 block w-full sm:text-sm">
              {_.startCase(balance?.type) || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">Created At</span>
            <div className="mt-1 block w-full sm:text-sm">
              {balance?.createdAt
                ? moment(balance.createdAt).format(dateFormat)
                : "N/A"}
            </div>
          </div>

        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Service Point Information</span>
        <div className="grid grid-cols-4 gap-6 mt-2">
          <div>
            <span className="block text-sm font-light text-gray-700">SPN</span>
            <div className="mt-1 block w-full sm:text-sm">
              {balance?.servicePoint?.code || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">Account Number</span>
            <div className="mt-1 block w-full sm:text-sm">
              {balance?.servicePoint?.accountCode || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Geo Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {balance?.servicePoint?.geoCode || "N/A"}
            </div>
          </div>

          <div className="col-start-1 col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Customer Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {balance?.servicePoint?.customer?.representative?.fullName ||
                balance?.servicePoint?.customer?.organization?.name ||
                balance?.servicePoint?.customer?.representative?.fullName ||
                "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Customer Phone
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {balance?.servicePoint?.customer?.representative?.phoneNumber ||
                balance?.servicePoint?.customer?.representative?.phoneNumber ||
                "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Customer Email
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {balance?.servicePoint?.customer?.representative?.emailAddress ||
                balance?.servicePoint?.customer?.representative?.emailAddress ||
                "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Meter Information</span>
        <div className="grid grid-cols-4 gap-6 mt-2">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {balance?.meter?.code || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Brand
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {balance?.meter?.modelMeta?.brandCode || "N/A"} -{" "}
              {balance?.meter?.modelMeta?.brandName || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Model
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {balance?.meter?.modelMeta?.modelCode || "N/A"} -{" "}
              {balance?.meter?.modelMeta?.modelName || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Actors Information</span>
        <div className="grid grid-cols-2 gap-6 mt-2">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Created By
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              <Owner owner={balance?.createdBy} type="staff-accounts" />
            </div>
          </div>

        </div>
      </div>


      <div className="pt-6">
        <span className="text-xs font-light">Balance Data</span>
        <div className="grid grid-cols-4 gap-6 mt-2">



          <div>
            <span className="block text-sm font-light text-gray-700">Meter Removal Date</span>
            <div className="mt-1 block w-full sm:text-sm">
              {balance?.createdAt
                ? moment(balance?.data?.finalReadingDate).format(dateFormat)
                : "N/A"}
            </div>
          </div>

          <div className='grid grid-cols-4 col-span-4  gap-6'>
            <div className='col-span-4'>
              <span className='block text-sm font-medium text-gray-700'>
                Previous Purchases
              </span>
              <div className='mt-1 w-full '>
                <table className='min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md'>
                  <thead className='bg-gray-50'>
                    <tr className='divide-x divide-gray-200'>
                      <th
                        scope='col'
                        className='whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900  text-left'
                      >
                        Date
                      </th>
                      <th
                        scope='col'
                        className='whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900  text-right w-96'
                      >
                        Amount
                      </th>

                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {balance?.data?.previousPurchases?.map((item: any, idx: number) => (
                      <tr key={idx} className="divide-x divide-gray-200">
                        <td className="whitespace- px-2 py-2 text-sm text-gray-700 text-ellipsis overflow-hidden ">{moment(item.date).format("MM/DD/YYYY")}</td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{item.amount ? `GHS ${item.amount}` : "N/A"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default BalanceView;
