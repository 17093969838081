import { FC } from "react";
import { Link, Navigate, Outlet, useLocation } from "react-location";
import { useReactiveVar } from "@apollo/client";
import _ from "lodash";
import routes from "router/routes";
import { clearAuth, currentTokenVar, currentUserVar } from "apollo/cache/auth";
import { classNames, wrapClick } from "utils";
import { Avatar } from "components/core";
import { withRoutePermissions } from "router/filter";
import {
  AcademicCapIcon,
  ArrowRightOnRectangleIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import { Disclosure, Transition } from "@headlessui/react";

const NAV_SECTIONS = [
  "Statistics",
  // "Customer Relations",
  "Customer Management",
  "Debt Administration",

];

const AppLayout: FC = () => {
  const currentUser = useReactiveVar(currentUserVar);
  const currentToken = useReactiveVar(currentTokenVar);

  const navigation = _.chain(routes)
    .filter((route) => _.get(route, "meta.layout") === "App")
    .thru(withRoutePermissions)
    .filter((item) => !!item.sidebar)
    .value();

  if (!currentToken) {
    if (window.location.hostname.includes("ecg.com.gh")) {
      window.location.href = "https://staff.ecg.com.gh";
    } else {
      return <Navigate to={"/signin"} replace />;
    }
  }
  return (
    <div className='h-screen'>
      <div className='h-full flex'>
        {/* Static sidebar for desktop */}
        <div className='hidden lg:flex lg:flex-shrink-0'>
          <div className='flex flex-col border-r border-gray-200 bg-primary-900 w-64'>
            <div className='flex flex-shrink-0 items-center border-b border-gray-500 p-4 flex-row'>
              <div className='h-10 w-10 p-1 flex-shrink-0 bg-indigo-800'>
                <img
                  className='h-full w-full aspect-h-1 aspect-w-1 border-white border p-1'
                  src={require("assets/logo.png")}
                  alt='SUBS'
                />
              </div>
              <div className='ml-3'>
                <p className='text-sm text-white font-mono'>
                  Debt Management
                  <br /> Console
                </p>
                {/* <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">View profile</p> */}
              </div>
            </div>
            <div className='py-4 flex flex-grow flex-col overflow-y-auto no-scrollbar'>
              <nav className='flex-1 space-y-4 px-2' aria-label='Sidebar'>
                {NAV_SECTIONS.map((section) => (
                  <Disclosure>
                    {({ open }) => (
                      <div key={section} className='space-y-1'>
                        <Disclosure.Button className='flex w-full justify-between rounded-lg pr-2 py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75'>
                          <h3
                            className='px-3 text-sm font-light text-gray-300'
                            id={section}
                          >
                            {section}
                          </h3>
                          <ChevronUpIcon
                            className={`${
                              !open ? "rotate-180 transform" : ""
                            } h-4 w-4 text-gray-300`}
                          />
                        </Disclosure.Button>
                        <Transition
                          show={open}
                          className='overflow-hidden'
                          enter='transition-all ease-in-out duration-[600ms] '
                          enterFrom='transform  max-h-0'
                          enterTo='transform  max-h-[1000px]'
                          leave='transition-all ease-in-out duration-[400ms]'
                          leaveFrom='transform  max-h-[1000px]'
                          leaveTo='transform  max-h-0'
                        >
                          <Disclosure.Panel className='space-y-1'>
                            <div
                              className='space-y-1'
                              role='group'
                              aria-labelledby={section}
                            >
                              {_.filter(
                                navigation,
                                (item) =>
                                  _.get(item, "meta.section") === section
                              ).map((item) => {
                                const isCurrent = false;
                                return (
                                  <Link
                                    key={item.sidebar?.label}
                                    to={item.path}
                                    getActiveProps={() => ({
                                      className: "bg-primary-800 text-white",
                                    })}
                                    getInactiveProps={() => ({
                                      className:
                                        "text-gray-300 hover:bg-primary-800 hover:text-white",
                                    })}
                                    className='group flex items-center px-2 py-2 text-sm rounded-md'
                                  >
                                    {item.sidebar?.icon && (
                                      <item.sidebar.icon
                                        className={classNames(
                                          isCurrent
                                            ? "text-gray-900"
                                            : "text-gray-200 group-hover:text-gray-50",
                                          "mr-3 flex-shrink-0 h-6 w-6"
                                        )}
                                        aria-hidden='true'
                                      />
                                    )}
                                    {item.sidebar?.label}
                                  </Link>
                                );
                              })}
                            </div>
                          </Disclosure.Panel>
                        </Transition>
                      </div>
                    )}
                  </Disclosure>
                ))}
              </nav>
            </div>
            <nav className='py-2 px-2 border-gray-500'>
              <a
                href='https://docs.subsenterprise.com/debt-management-console/'
                target='_blank'
                className='group w-full flex items-center px-2 py-2 text-sm rounded-md text-gray-300 hover:bg-primary-800 hover:text-white'
                rel='noreferrer'
              >
                <AcademicCapIcon
                  className={classNames(
                    "text-gray-200 group-hover:text-gray-50",
                    "mr-3 flex-shrink-0 h-6 w-6"
                  )}
                  aria-hidden='true'
                />
                Tutorials
              </a>
              <button
                onClick={wrapClick(clearAuth)}
                className='group w-full flex items-center px-2 py-2 text-sm rounded-md text-gray-300 hover:bg-primary-800 hover:text-white'
              >
                <ArrowRightOnRectangleIcon
                  className={classNames(
                    "text-gray-200 group-hover:text-gray-50",
                    "mr-3 flex-shrink-0 h-6 w-6"
                  )}
                  aria-hidden='true'
                />
                Logout
              </button>
            </nav>

            <div className='flex flex-shrink-0 border-t border-gray-500 p-4'>
              <Link
                to='/settings/#profile'
                className='group block w-full flex-shrink-0'
              >
                <div className='flex items-center'>
                  <button onClick={wrapClick(clearAuth)}>
                    <Avatar
                      src={currentUser?.profileImageUrl}
                      alt={
                        [
                          currentUser?.firstName || "",
                          currentUser?.lastName || "",
                        ]
                          .join(" ")
                          .trim() || "N A"
                      }
                    />
                  </button>
                  <div className='ml-3'>
                    <p className='text-sm font-medium text-gray-200 group-hover:text-white line-clamp-1'>
                      {[
                        currentUser?.firstName || "",
                        currentUser?.lastName || "",
                      ]
                        .join(" ")
                        .trim()}
                    </p>
                    <p className='text-xs font-medium text-gray-300 group-hover:text-gray-100 line-clamp-1'>
                      {currentUser?.emailAddress}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className='flex-1 min-w-0 flex flex-col overflow-hidden'>
          {/* Display content here */}
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
