import { XMarkIcon } from "@heroicons/react/24/outline";
import { TextInput } from "components/core";
import { useFormik } from "formik";
import { FC, useEffect } from "react";
import _ from "lodash";
import { classNames, wrapClick } from "utils";
import { Switch } from "@headlessui/react";
import moment from "moment";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import numeral from "numeral";
import { RecoveryInfoFormSchema } from "./schema";

const DATE_FORMAT = "YYYY-MM-DD";

interface RecoveryInfoFormProps {
  handleNext: (values: any) => void;
  handlePrevious: () => void;
  initialValues: any;
  values: any;
  handleCancel: () => void;
  replacement: any;
}

const RecoveryInfoForm: FC<RecoveryInfoFormProps> = ({
  initialValues,
  handleNext,
  handlePrevious,
  handleCancel,
  replacement,
}) => {
  const form = useFormik({
    initialValues,
    validationSchema: RecoveryInfoFormSchema,
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  const removeItem = (field: string, id: number) => {
    form.setFieldValue(field, [
      ..._.get(form.values, field)?.filter(
        (val: string, _id: number) => _id !== id
      ),
    ]);
  };

  useEffect(() => {
    if (form.values.enterAmountManually) {
      const momentDates: moment.Moment[] = form.values.previousPurchases.map(
        (previousPurchase: any) => moment(previousPurchase?.date)
      );
      const minDate = moment.min(momentDates);
      const maxDate = moment.max(momentDates);
      const dayInterval = maxDate.diff(minDate, "days");
      const totalAmount = _.sum(
        form.values.previousPurchases
          .slice(0, -1)
          .map((previousPurchase: any) => previousPurchase?.amount)
      );
      if (dayInterval && totalAmount) {
        const finalReadingDayDiff = Math.abs(
          moment(form.values.finalReadingDate).diff(maxDate, "days")
        );
        const dailyAverage = totalAmount / dayInterval;
        const amount =
          dailyAverage * finalReadingDayDiff -
          form.values.previousPurchases[
            form.values.previousPurchases.length - 1
          ].amount;
        console.log({ totalAmount, amount });
        form.setFieldValue("amount", _.round(amount, 2));
      }
    } else {
      const llAmount =
        numeral(replacement.resolution?.recoveryData?.creditAmount || "0")
          .subtract(replacement?.resolution?.recoveryData?.debtAmount || "0")
          .value() || 0;
      form.setFieldValue("amount", llAmount);
    }
  }, [form.values.previousPurchases, form.values.enterAmountManually]);

  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <form
      onSubmit={form.handleSubmit}
      className="flex-1 flex flex-col overflow-hidden"
    >
      <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto">
        <div>
          <span className="text-xs font-light">Meter Balance Information</span>
          <div className="grid grid-cols-4 gap-6 mt-2">
            <Switch.Group as="div" className="col-span-3">
              <span className="flex flex-grow flex-col">
                <Switch.Label
                  as="span"
                  className="text-sm font-medium text-gray-900"
                  passive
                >
                  Estimate Meter Balance from Previous Purchases or Enter
                  Balance Manually?
                </Switch.Label>
              </span>
              <div className="flex items-center justify-between mt-1 space-x-3 h-[38px]">
                <Switch.Description as="span" className="text-sm text-gray-500">
                  Toggle on to allow manual entering of meter balance and purchase history or procede
                  to use meter balance captured from field by installer.
                </Switch.Description>
                <Switch
                  id='enterAmountManually'
                  checked={form.values.enterAmountManually}
                  onChange={(val: boolean) =>
                    form.setFieldValue("enterAmountManually", val)
                  }
                  className={classNames(
                    form.values.enterAmountManually
                      ? "bg-primary-600"
                      : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      form.values.enterAmountManually
                        ? "translate-x-5"
                        : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </div>
            </Switch.Group>

            <div className="col-span-2">
              <TextInput
                id="amount"
                label="Meter Balance"
                type={!form.values.enterAmountManually ? "text" : "number"}
                step={0.01}
                disabled={!form.values.enterAmountManually}
                placeholder={form.values.enterAmountManually && "eg. 200"}
                required={form.values.enterAmountManually}
                postText={
                  "GHS" +
                  (form.values.amount > 0
                    ? " (Credit)"
                    : form.values.amount < 0
                    ? " (Debt)"
                    : "")
                }
                {...form}
                handleChange={(e: any) => {
                  form.setFieldValue("previousPurchases", []);
                  form.handleChange(e)
                }}
                values={{
                  amount: !form.values.enterAmountManually
                    ? numeral(
                        replacement.resolution?.recoveryData?.creditAmount ||
                          "0"
                      )
                        .subtract(
                          replacement?.resolution?.recoveryData?.debtAmount ||
                            "0"
                        )
                        .format("0,0.00")
                    : _.get(form.values, "amount"),
                }}
              />
            </div>
            <div className="col-span-2">
              <TextInput
                id="finalReadingDate"
                label="Meter Removal Date"
                disabled={true}
                values={{
                  finalReadingDate: _.get(form.values, "finalReadingDate", "")
                    ? moment(_.get(form.values, "finalReadingDate", "")).format(
                        dateFormat
                      )
                    : "N/A",
                }}
                handleChange={undefined}
                handleBlur={undefined}
              />
            </div>

            {form.values.enterAmountManually && (
              <div className="col-span-4">
                <span className="block text-sm font-medium text-gray-700">
                  Previous Purchases *
                </span>
                <div className="mt-1 w-full ">
                  <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
                    <thead className="bg-gray-50">
                      <tr className="divide-x divide-gray-200">
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-right text-sm font-semibold text-gray-900 w-96"
                        >
                          Amount
                        </th>

                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-right w-[38px]"
                        ></th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {_.times(
                        form.values.previousPurchases?.length + 1,
                        (idx) => {
                          const id = `previousPurchases.${idx}`;

                          const isLast =
                            (form.values.previousPurchases?.length || 0) ===
                            idx;
                          return (
                            <tr key={idx} className="divide-x divide-gray-200">
                              <td className="p-0">
                                <input
                                  key={idx}
                                  type={"date"}
                                  id={`${id}.date`}
                                  name={`${id}.date`}
                                  disabled={
                                    idx !== 0 &&
                                    !_.get(
                                      form.values,
                                      `previousPurchases.${idx - 1}.date`,
                                      ""
                                    )
                                  }
                                  min={moment(
                                    _.get(
                                      form.values,
                                      `previousPurchases.${idx - 1}.date`,
                                      ""
                                    )
                                  )
                                    .add(1, "days")
                                    .format(DATE_FORMAT)}
                                  max={moment(
                                    _.get(form.values, `finalReadingDate`, "")
                                  ).format(DATE_FORMAT)}
                                  required={
                                    idx < 3 && !form.values.enterAmountManually
                                  }
                                  value={_.get(form.values, `${id}.date`, "")}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                  className={classNames(
                                    _.get(form.errors, `${id}.date`) &&
                                      _.get(form.touched, `${id}.date`)
                                      ? "focus:ring-red-500 focus:border-red-500 border-red-600 border"
                                      : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                                    "block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px] border-0 focus:border disabled:bg-gray-100 disabled:cursor-not-allowed"
                                  )}
                                />
                              </td>
                              <td className="p-0 text-right">
                                <div className="relative">
                                  <input
                                    key={idx}
                                    type={"number"}
                                    id={`${id}.amount`}
                                    required={
                                      idx < 3 &&
                                      !form.values.enterAmountManually
                                    }
                                    name={`${id}.amount`}
                                    value={_.get(
                                      form.values,
                                      `${id}.amount`,
                                      ""
                                    )}
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                    placeholder={"eg. 50"}
                                    step={0.0001}
                                    disabled={
                                      idx !== 0 &&
                                      (!_.get(
                                        form.values,
                                        `previousPurchases.${idx - 1}.date`,
                                        ""
                                      ) ||
                                        !_.get(form.values, `${id}.date`, ""))
                                    }
                                    min={0}
                                    className={classNames(
                                      _.get(form.errors, `${id}.amount`) &&
                                        _.get(form.touched, `${id}.amount`)
                                        ? "focus:ring-red-500 focus:border-red-500 border-red-600 border"
                                        : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                                      "block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px] border-0 focus:border text-right disabled:bg-gray-100 disabled:cursor-not-allowed"
                                    )}
                                  />
                                  <div className="absolute left-2 top-2.5 text-sm  text-gray-500">
                                    GHS
                                  </div>
                                </div>
                              </td>

                              <td className="p-0 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400 text-right w-[38px] h-[38px] items-center justify-center flex">
                                {!isLast && idx > 2 && (
                                  <button
                                    type="button"
                                    onClick={wrapClick(() =>
                                      removeItem("previousPurchases", idx)
                                    )}
                                    className="inline-flex items-center rounded-full border border-transparent p-1 text-red-500 hover:bg-red-300 hover:text-red-900 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                  >
                                    <XMarkIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
                {_.get(form.errors, "previousPurchases") && _.get(form.touched, "previousPurchases") ? (
                  <p className="mt-2 text-sm text-red-600" id={`previousPurchases-error`}>
                    { typeof _.get(form.errors, "previousPurchases") == "string" ?
                      `${_.get(form.errors, "previousPurchases")}`
                      : typeof _.get(form.errors, "previousPurchases") == "object" ? 
                      _.map(_.get(form.errors, "previousPurchases"), (val: any, idx: any) => _.values(val).map(err => <div key={idx}>{`${err}`}<br/></div>))
                      : null
                    }
                  </p>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="submit"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default RecoveryInfoForm;
