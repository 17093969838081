import { gql, useLazyQuery } from "@apollo/client";
import {
  ArrowTopRightOnSquareIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import { Avatar, TextInput } from "components/core";
import { FormikProps, useFormik } from "formik";
import { FC, useState } from "react";
import { Link, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { classNames, wrapClick } from "utils";

interface AssignPlotFormProps {
  form: FormikProps<{
    team: any;
  }>;
}

function UserOwner({ owner, type }: { owner: any; type?: "leader" }) {
  return (
    <div
      className={classNames(
        type === "leader"
          ? "border-green-600 bg-green-50 dark:border-green-800 dark:bg-green-800 hover:border-green-400 dark:hover:border-green-500"
          : "border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 hover:border-gray-400 dark:hover:border-gray-500",
        "focus:outline-none relative rounded-lg border px-3 py-3 shadow-sm flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
      )}
    >
      <div className="flex-shrink-0">
        <Avatar
          alt={
            [(owner?.lastName || "")?.trim(), (owner?.firstName || "")?.trim()]
              .join(" ")
              .trim() || "N A"
          }
          src={owner?.profileImageUrl || ""}
        />
      </div>
      <div className="flex-1 min-w-0 text-left">
        <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
          {[(owner?.lastName || "")?.trim(), (owner?.firstName || "")?.trim()]
            .join(" ")
            .trim() || "N A"}
        </p>
        <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
          {owner?.phoneNumber || "N/A"}
        </p>
      </div>
    </div>
  );
}

function Owner({
  owner,
  onClick,
  isActive,
}: {
  owner: any;
  onClick: any;
  isActive: boolean;
}) {
  return (
    <button
      onClick={onClick}
      className={classNames(
        isActive
          ? "border-primary-500 dark:border-primary-600 bg-white dark:bg-primary-800 hover:border-primary-700 dark:hover:border-primary-500"
          : "border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 hover:border-gray-400 dark:hover:border-gray-500",
        "focus:outline-none relative rounded-lg border px-3 py-3 shadow-sm flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
      )}
    >
      <div className="flex-shrink-0">
        <Avatar
          alt={(owner?.name || "")?.trim() || "N A"}
          src={owner?.profileImageUrl || ""}
        />
      </div>
      <div className="flex-1 min-w-0 text-left">
        <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
          {(owner?.name || "")?.trim() || "N A"}
        </p>
        <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
          {[owner?.leader?.lastName, owner?.leader?.firstName]
            .join(" ")
            .trim() || "N/A"}
        </p>
      </div>
      <div className="flex-shrink-0">
        <Link
          type="button"
          // to={"/field-agents/"}
          search={{ id: owner?._id }}
          target={"_blank"}
          className="w-8 h-8 bg-white hover:bg-gray-100 dark:bg-gray-900 dark:hover:bg-gray-900 inline-flex items-center justify-center text-gray-400 dark:text-gray-400 rounded-full bg-transparent hover:text-gray-500 dark:hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          <span className="sr-only">View team details</span>
          <ArrowTopRightOnSquareIcon className="w-5 h-5" aria-hidden="true" />
        </Link>
      </div>
    </button>
  );
}

const GET_USER_TEAMS = gql`
  query GetUserTeams(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
  ) {
    getUserTeams(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
    ) {
      _id
      code
      name
      leader {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        gender
        profileImageUrl
        role {
          _id
          code
          name
        }
        createdAt
        updatedAt
      }
      members {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        gender
        profileImageUrl
        role {
          _id
          code
          name
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

const AssignPlotForm: FC<AssignPlotFormProps> = ({ form }) => {
  const searchParams = useSearch<LocationGenerics>()
  const [getUserTeams, { loading, data }] = useLazyQuery(GET_USER_TEAMS);

  const searchForm = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: (values) => {
      getUserTeams({
        variables: {
          page: 0,
          pageSize: 0,
          search: values.search,
          searchFields: ["name"],
          region: searchParams.region || undefined,
          district: searchParams.district || undefined,
        },
      });
    },
  });

  return (
    <div className="space-y-6 divide-y divide-gray-200 min-h-[65vh]">
      <div>
        <span className="text-xs font-light">Current Assignment</span>
        {form?.values?.team ? (
          <div className="border mt-2 border-gray-300 border-dashed bg-gray-100 rounded-md">
            <div className="grid grid-cols-4 gap-x-4 gap-y-6 p-4">
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Team Code
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {form?.values?.team?.code || "N/A"}
                </div>
              </div>

              <div>
                <span className="block text-sm font-light text-gray-700">
                  Team Name
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {form?.values?.team?.name || "N/A"}
                </div>
              </div>
            </div>

            <div className="p-4">
              <span className="block text-sm font-light text-gray-700">
                Team Members
              </span>
              <div className="grid grid-cols-3 gap-6 mt-2">
                <UserOwner
                  key={"leader"}
                  owner={form?.values?.team?.leader}
                  type="leader"
                />
                {form?.values?.team?.members?.map?.((user: any) => (
                  <UserOwner key={user._id} owner={user} />
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex  mt-2 w-full items-center justify-center">
            <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-6 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
              <UserPlusIcon
                className="mx-auto h-10 w-10 text-gray-400"
                stroke="currentColor"
                strokeWidth={1}
                aria-hidden="true"
              />
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No team assigned
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Get started by searching for a team below.
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Select Team</span>
        <form
          onSubmit={searchForm.handleSubmit}
          className="grid grid-cols-5 gap-6 mt-2"
        >
          <div className="col-span-6 sm:col-span-3">
            <TextInput
              id="search"
              label="Team Name"
              type="text"
              placeholder="e.g. Team 01"
              {...searchForm}
            />
          </div>
          <div className="col-span-6 sm:col-span-1 items-end justify-end flex">
            <button
              type="submit"
              className="w-full inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
            >
              {loading ? "Searching..." : "Search"}
            </button>
          </div>
        </form>
        <div className="grid grid-cols-3 gap-6 mt-6 pt-6 border-t border-gray-200">
          {data?.getUserTeams?.map?.((team: any) => (
            <Owner
              key={team._id}
              isActive={team._id === form.values.team?._id}
              onClick={wrapClick(() => form.setFieldValue("team", team))}
              owner={team}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AssignPlotForm;
