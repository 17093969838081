import { gql, useMutation } from "@apollo/client";
import { Modal } from "components";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick } from "utils";

const INITIATE_DISCONNECTION = gql`
  mutation InitiateDisconnection(
    $region: ID!
    $district: ID!
    $count: Int!
    $serviceClasses: [ServiceClass]
    $accountType: AccountType
  ) {
    flagDebtorsForDisconnection(
      region: $region
      district: $district
      count: $count
      serviceClasses: $serviceClasses
      accountType: $accountType
    )
  }
`;

export default function InitiateDisconnectionContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [initiateDisconnection] = useMutation(INITIATE_DISCONNECTION);

  const form = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      await initiateDisconnection({
        variables: {
          id: searchParams.id,
          count: searchParams.count,
          region: searchParams.region || undefined,
          district: searchParams.district || undefined,
          accountType: searchParams.accountType || undefined,
          ...(searchParams.sltStatus
            ? {
                serviceClasses:
                  searchParams.sltStatus === "NSLT"
                    ? ["Residential", "NonResidential"]
                    : ["Industrial", "SpecialLoadTariff"],
              }
            : {}),
        },
      }).then(({ data }) => {
        if (!!data.flagDebtorsForDisconnection) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Disconnections Prioritized Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not prioritize disconnections disconnection",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Prioritize Disconnections"
      description="Provide details to prioritize disconnections"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={form.isSubmitting}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {form.isSubmitting
              ? "Prioritizing Disconnections"
              : "Prioritize Disconnections"}
          </button>
        </>
      )}
    >
      <div>
        <p>
          The top {searchParams?.count} {searchParams?.sltStatus} {searchParams?.accountType} debtors would be prioritized for
          disconnection. Do you want to proceed?
        </p>
      </div>
    </Modal>
  );
}
