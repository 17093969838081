import { gql, useQuery } from "@apollo/client";
import { ServicePointView, Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";

export const GET_SERVICE_POINT = gql`
  query GetServicePoint($id: ID!) {
    servicePoint: getServicePoint(id: $id) {
      _id
      code
      qrCode
      geoLocation {
        type
        coordinates
      }
      regionCode
      regionName
      districtCode
      districtName
      blockName
      roundName
      roundCode
      plotCode
      itinerary {
        _id
        code
      }
      property {
        _id
        code
        qrCode
        address
        ghanaPostAddress
        streetName
        houseNumber
        houseNumberSlateUrl
        closestLandmark
        structureNumber
        sitePlanDocumentUrl
        owner {
          fullName
          phoneNumber
        }
        premiseType {
          _id
          code
          name
        }
        premiseCategory {
          _id
          code
          name
        }
        blockName
        roundName
        plotName
        itineraryCode
        community
      }
      customer {
        _id
        code
        customerType

        organization {
          name
          taxIdentificationNumber
          organizationRegistrationNumber
          organizationRegistrationDate
          organizationRegistrationDocumentUrl
          certificateOfIncorporationDocumentUrl
        }
        representative {
          title
          fullName
          nationality
          dateOfBirth
          gender
          phoneNumber
          emailAddress
          profileImageUrl
          hasGhanaCard
          ghanaCardNumber
          ghanaCardIssueDate
          ghanaCardExpiryDate
          ghanaCardFrontImageUrl
          ghanaCardBackImageUrl
          identityCardType
          identityCardNumber
          identityCardIssueDate
          identityCardExpiryDate
          identityCardFrontImageUrl
          identityCardBackImageUrl
        }
        status
        createdAt
        updatedAt
      }
      account {
        _id
        code
        status
        balance
        balanceUpdatedAt
        meta {
          lastPaymentAmount
          lastPaymentDate
          lastBillAmount
          lastBillDate
          lastReadingValue
          lastReadingDate
        }
        createdAt
        updatedAt
      }
      meter {
        _id
        code
        batchCode
        system {
          _id
          code
          name
        }
        brand {
          _id
          code
          name
        }
        model {
          _id
          code
          name
          phase
          type
          digits
          voltage
          currentRating
          mechanism
          communicationType
        }
        status
        balance
        balanceUpdatedAt
        meta {
          lastReadingValue
          lastReadingDate
        }
      }
      meterCode
      serviceType
      serviceClass
      tariffClass {
        _id
        code
        name
        serviceClass
      }
      geoCode
      transformerPhase
      meterLocation
      meterHeight
      transformerNumber
      currentTransformerRatio
      deliveryPointNumber
      contractedDemand
      accountCode
      poleNumber
      energyCertificateNumber
      energyCertificateDocumentUrl
      activity {
        _id
        code
        name
      }
      subActivity {
        _id
        code
        name
      }
      appliances {
        appliance {
          _id
          code
          name
          wattage
        }
        quantity
      }
      status
      meta {
        lastBillValue
        lastBillDate
        lastReadingValue
        lastReadingDate
      }
      accountData {
        lastPaymentDate
        lastBillAmount
        debtAmount
        toBeDisconnected
      }
      createdAt
      updatedAt
    }
  }
`;

export default function ViewServicePointContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_SERVICE_POINT, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title='Service Point Information'
      loading={loading}
      description='Details of service points are shown below'
    >
      <ServicePointView servicePoint={data?.servicePoint} />
    </Modal>
  );
}
