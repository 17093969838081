import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import { OfficeHeader, TextInput } from "components";
import config from "config";
import { FC, useEffect } from "react";
import { Link, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { classNames, useUrlState, withPermissions } from "utils";
import ManageDebtorSets from "./manage-debtor-sets";
import ManageDebtors from "./manage-debtors";
import InitiateDisconnectionContainer from "./initiate";

const orderStatuses = [
  { name: "Debtors", href: "Debtors" },
  { name: "Unassigned Debtor Sets", href: "UnassignedDebtorSets" },
  { name: "Assigned Debtor Sets", href: "AssignedDebtorSets" },
];

const DebtCustomersPage: FC = () => {
  const navigate = useNavigate<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [orderStatus, setOrderStatus] = useUrlState("orderStatus");
  const [count, setCount] = useUrlState("count");

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.sort ? { sort: "" } : {}),
        ...(!old?.count ? { count: "1000" } : {}),
        ...(!old?.orderStatus ? { orderStatus: "Debtors" } : {}),
      }),
    });
  }, [navigate]);

  useEffect(() => {
    if(!orderStatus) {
      setOrderStatus("Debtors")
    }
  }, [orderStatus])

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <OfficeHeader
        renderActions={() => (
          <>
            <div className="w-52">
              <TextInput
                id={"count"}
                label={""}
                labelHidden
                placeholder="Top N Debtors"
                values={{ count }}
                handleChange={(e: any) => setCount(e?.target?.value)}
                handleBlur={undefined}
                type="number"
                step={1000}
                postText="Top Debtors"
              />
            </div>
            {withPermissions(["*:*", "disconnection-service-orders:*"]) && (searchParams?.region && searchParams?.district && searchParams?.count) && (
              <button
                type="button"
                onClick={() => setModal("initiate")}
                className="ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                <DocumentPlusIcon
                  className="-ml-0.5 mr-2 h-4 w-4"
                  aria-hidden="true"
                />
                Prioritize Disconnections
              </button>
            )}
          </>
        )}
      />

      <div className="block">
        <div className="border-b border-gray-200 bg-white px-6">
          <nav className="-mb-px flex space-x-8 items-center" aria-label="Tabs">
            {orderStatuses.map((_orderStatus) => (
              <Link
                key={_orderStatus.name}
                search={(old) => ({
                  ...old,
                  orderStatus: _orderStatus.href,
                  id: undefined,
                  meta: undefined,
                })}
                className={classNames(
                  orderStatus === _orderStatus.href
                    ? "border-primary-500 text-primary-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={
                  orderStatus === _orderStatus.href ? "page" : undefined
                }
              >
                {_orderStatus.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
      {orderStatus === "Debtors" && <ManageDebtors />}
      {orderStatus === "UnassignedDebtorSets" && <ManageDebtorSets status="Unassigned" />}
      {orderStatus === "AssignedDebtorSets" && <ManageDebtorSets status="Assigned" />}
      <InitiateDisconnectionContainer
        open={modal === "initiate"}
        setOpen={(val: boolean) => setModal(val ? "initiate" : undefined)}
        refetch={() => {}}
      />
    </main>
  );
};

export default DebtCustomersPage;
