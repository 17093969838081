import RecoveryInfoForm from "./balance-info-form";
import ChargesInfoForm from "./balance-info-summary";

const formSteps = [
  {
    name: 'Balance Info',
    description: "Information about user to approve to service order.",
    accessor: "recoveryInfo",
    FormComponent: RecoveryInfoForm,
  },
  {
    name: 'Preview Info',
    description: 'Information about wallet to be credited.',
    accessor: "chargesInfo",
    FormComponent: ChargesInfoForm,
  },
]

export default formSteps;
