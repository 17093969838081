import { gql, useMutation, useQuery } from "@apollo/client";
import { Modal, CancelDebtForm } from "components";
import { useFormik } from "formik";
import { LocationGenerics } from "router/location";
import { useSearch } from "react-location";
import { useEffect } from "react";
import moment from "moment";
import toast from "react-hot-toast";
import { GET_DEBT } from "./view";
import * as Yup from "yup";
import { wrapClick } from "utils";

const CANCEL_DEBT = gql`
  mutation CancelDebt($id: ID!, $reason: String!) {
    debt: cancelDebt(id: $id, reason: $reason) {
      _id
    }
  }
`;

export default function CancelDebtContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [cancelDebt] = useMutation(CANCEL_DEBT);
  const { data, loading } = useQuery(GET_DEBT, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const form = useFormik({
    initialValues: {
      reason: "",
      code: "",
    },
    validationSchema: Yup.object().shape({
      reason: Yup.string().required("Kindly enter the cancellation reason"),
      code: Yup.string().test("match", "Value Entered Does Not Match Debt Code", (current) => {
        return current === data?.debt?.code;
      }).required(),
    }),
    onSubmit: async (values) => {
      //console.log("tripping", values);

      await cancelDebt({
        variables: {
          id: searchParams.id,
          reason: values.reason
        },
      }).then(({ data }) => {
        if (data.debt._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Debt cancelled Successfully",
            })
          );
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not cancel debt",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Cancel Debt"
      description="Provide reason to cancel debt"
      size="5xl"
      renderActions={() => (
        <>
          <button
            type='button'
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {form.isSubmitting ? "Cancelling Debt ..." : "Cancel Debt"}
          </button>
        </>
      )}
    >
      {data?.debt?._id && <CancelDebtForm debt={data?.debt} form={form} />}
    </Modal>
  );
}
