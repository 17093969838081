import { FC } from "react";
import TableBodyComponent from "./table-body";
import TableEmptyComponent from "./table-empty";
import TableFooterComponent from "./table-footer";
import TableHeaderComponent from "./table-header";
import { useUrlState } from "utils";
import TableGridBodyComponent from "./table-grid-body";

interface TableComponentProps<TData = any> {
  title: string;
  data: {
    rows: TData[];
    total: number;
    page: number;
    pageSize: number;
    totalPages: number;
  };
  loading?: boolean;
  isRefetching?: boolean;
  renderColumns?: FC<TData>;
  renderItem?: FC<TData>;
  renderGridItem?: FC<TData>;
  renderFilter?: FC<{
    filterOpen: boolean;
    setFilterOpen: (val: boolean) => void;
  }>;
  renderExport?: FC<{
    exportOpen: boolean;
    setExportOpen: (val: boolean) => void;
  }>;
  renderLoader?: FC;
  renderSearchOption?: FC;
  renderGridLoader?: FC;
  renderHeaderItems?: FC;
  refetch: () => void;
  hasSearch?: boolean;
  disableSearch?: boolean;
  onSearchClicked?: (searchValue: string) => void;
  defaultView?: "grid" | "list";
  paginationPrefix?: string;
}

const TableComponent: FC<TableComponentProps> = ({
  isRefetching,
  defaultView,
  title,
  data,
  loading,
  renderGridLoader,
  renderColumns,
  renderItem,
  renderFilter,
  renderGridItem,
  renderExport,
  refetch,
  renderLoader,
  renderHeaderItems,
  onSearchClicked,
  hasSearch,
  disableSearch,
  renderSearchOption,
  paginationPrefix
}) => {
  const [viewType] = useUrlState("view-type");
  const hasGridMode = !!renderGridItem;
  return (
    <div className="space-y-6 flex-1 flex flex-col overflow-y-hidden min-w-full">
      <div className="px-4 sm:px-6">
        <TableHeaderComponent
          title={title}
          renderFilter={renderFilter}
          renderSearchOption={renderSearchOption}
          disableSearch={disableSearch}
          renderExport={renderExport}
          gridable={hasGridMode}
          refetch={refetch}
          renderHeaderItems={renderHeaderItems}
          hasSearch={hasSearch}
          loading={isRefetching}
          defaultView={defaultView}
          onSearchClicked={onSearchClicked}
        />
      </div>
      {!loading && (data?.total || 0) === 0 ? (
        <TableEmptyComponent />
      ) : (
        <>
          {viewType === "grid" && hasGridMode ? (
            <TableGridBodyComponent
              data={data}
              loading={loading}
              renderLoader={renderGridLoader}
              renderItem={renderGridItem}
            />
          ) : (
            <TableBodyComponent
              data={data}
              loading={loading}
              renderColumns={renderColumns}
              renderLoader={renderLoader}
              renderItem={renderItem}
            />
          )}

          <div className="px-4 sm:px-6">
            <TableFooterComponent data={data} paginationPrefix={paginationPrefix} />
          </div>
        </>
      )}
    </div>
  );
};

export default TableComponent;
