import { FC } from "react";
import Map from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MapProvider, Marker } from "react-map-gl";
import config from "config";
import { wrapImage } from "utils";
import moment from "moment";
import numeral from "numeral";

interface ReplacementResolutionViewProps {
  resolution: {
    recoveryData: any;
    previousReading: any;
    meter: any;
    ghanaPostAddress: string;
    address: string;
    accountCode: string;
    structureNumber: string;
    property: any;
    cartesianLocation: any;
    customer: any;
    geoCode: string;
    region: any;
    district: any;
    block: any;
    round: any;
    plot: any;
    code: string;
    houseNumberSlateUrl: string;
    regionCode: string;
    regionName: string;
    districtCode: string;
    districtName: string;
    blockCode: string;
    blockName: string;
    roundCode: string;
    roundName: string;
    plotCode: string;
    community: string;
    itineraryCode: string;
    itineraryDescription: string;
    geoLocation: {
      coordinates: [number, number];
    };
    meta: {
      customersCount: number;
    };
  };
}

const ReplacementResolutionView: FC<ReplacementResolutionViewProps> = ({
  resolution,
}) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Replacement Information</span>
        <div className="grid grid-cols-4 gap-6 mt-2">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Replacement Date
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {resolution?.previousReading?.readingDate
                ? moment(resolution?.previousReading?.readingDate).format(
                  "DD/MM/YYYY"
                )
                : "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Balance
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              GHS{" "}
              {numeral(resolution?.recoveryData?.creditAmount || "0").subtract(resolution?.recoveryData?.debtAmount || "0").format("0,0.00")}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Status
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {resolution?.meter?.status || "N/A"}
            </div>
          </div>

          <div className="col-span-4">
            <span className="block text-sm font-light text-gray-700">
              Balance Image
            </span>
            {resolution?.previousReading?.readingImageUrl ? (
              <div className="mt-1 block w-full">
                {wrapImage(
                  <img
                    src={resolution?.previousReading?.readingImageUrl}
                    alt={"im"}
                    className="h-64 w-full object-cover object-center"
                  />
                )}
              </div>
            ) : (
              <div className="mt-1 block w-full sm:text-sm">{"N/A"}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReplacementResolutionView;
