import { SelectInput, TextArea, TextInput } from "components/core";
import { useFormik } from "formik";
import { FC, useEffect } from "react";
import _ from "lodash";
import { classNames, wrapClick } from "utils";
import { DebtInfoFormSchema } from "./schema";
import { Switch } from "@headlessui/react";
import moment from "moment";
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import numeral from "numeral";
import lodash from "lodash";

const DATE_FORMAT = "YYYY-MM-DD";

interface DebtInfoFormProps {
  handleNext: (values: any) => void;
  handlePrevious: () => void;
  initialValues: any;
  values: any;
  handleCancel: () => void;
}

const DebtInfoForm: FC<DebtInfoFormProps> = ({
  initialValues,
  handleNext,
  handlePrevious,
  handleCancel,
}) => {
  const form = useFormik({
    initialValues,
    validationSchema: DebtInfoFormSchema,
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  const removeItem = (field: string, id: number) => {
    form.setFieldValue(field, [
      ..._.get(form.values, field)?.filter(
        (val: string, _id: number) => _id !== id
      ),
    ]);
  };

  useEffect(() => {
    if (form.values.sendWholeDebt === true) {
      if (form.values.amount) {
        form.setFieldValue("installments", [
          {
            amount: form.values.amount,
            dueDate: moment().add(365, "days").format(DATE_FORMAT),
          },
        ]);
      }
    } else {
      if (form.values.amount) {
        form.setFieldValue("installments", [
          {
            amount: form.values.amount,
            dueDate: moment().add(1, "day").format(DATE_FORMAT),
          },
        ]);
      }
    }
  }, [form.values.sendWholeDebt, form.values.amount]);

  useEffect(() => {
    if (
      form.values.sendWholeDebt === false &&
      !form.values?.installments?.length
    ) {
      if (form.values.amount) {
        form.setFieldValue("installments", [
          {
            amount: form.values.amount,
            dueDate: moment().add(365, "days").format(DATE_FORMAT),
          },
        ]);
      }
    }
  }, []);

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto">
        <div>
          <span className="text-xs font-light">Debt Information</span>
          <div className="grid grid-cols-4 gap-6 mt-2">
          <div className="">
              <TextInput
                id="amount"
                label="Debt Amount"
                type="number"
                step={0.01}
                min={0}
                placeholder="eg. 3"
                required={true}
                postText="GHS"
                {...form}
              />
            </div>
            <div className="">
              <SelectInput
                options={[
                  {label: "Debt Recovery", value: "DebtRecovery"},
                  {label: "Revenue Protection", value: "RevenueProtection"},
                ]}
                id="type"
                label="Debt Type"
                placeholder="eg. Debt Recovery"
                required={true}
                {...form}
                />
            </div>
            <div className="col-span-4">
              <TextArea
                id="description"
                label="Debt Description"
                placeholder="eg. Recovery for power wastage"
                required={true}
                {...form}
              />
            </div>
          </div>
        </div>
        {!!form.values.amount && (
          <div className="pt-6">
            <span className="text-xs font-light">Plan Information</span>
            <div className="grid grid-cols-3 gap-6 mt-2">
              <Switch.Group as="div" className="col-span-2">
                <span className="flex flex-grow flex-col">
                  <Switch.Label
                    as="span"
                    className="text-sm font-medium text-gray-700"
                    passive
                  >
                    Send Whole Debt?
                  </Switch.Label>
                </span>
                <div className="flex items-center justify-between mt-1  h-[38px]">
                  <Switch.Description
                    as="span"
                    className="text-sm text-gray-500"
                  >
                    Turn off to send debt to prepayment system in installments
                  </Switch.Description>
                  <Switch
                    checked={form.values.sendWholeDebt}
                    onChange={(val: boolean) =>
                      form.setFieldValue("sendWholeDebt", val)
                    }
                    className={classNames(
                      form.values.sendWholeDebt
                        ? "bg-primary-600"
                        : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        form.values.sendWholeDebt
                          ? "translate-x-5"
                          : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </div>
              </Switch.Group>
              {!form.values.sendWholeDebt ? (
                <div className="col-span-3">
                  <span className="block text-sm font-medium text-gray-700">
                    Installments *
                  </span>
                  <div className="mt-1 w-full ">
                    <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
                      <thead className="bg-gray-50">
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Plan Number
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Amount
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Due Date
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 w-[38px]"
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {_.times(
                          form.values.installments?.length + 1,
                          (idx) => {
                            const id = `installments.${idx}`;
                            const isLast =
                              (form.values.installments?.length || 0) === idx;

                            return (
                              <tr
                                key={idx}
                                className="divide-x divide-gray-200"
                              >
                                <td className="p-0">
                                  <input
                                    key={idx}
                                    type={"text"}
                                    id={`index`}
                                    name={`index`}
                                    value={`${numeral(idx + 1).format(
                                      "0o"
                                    )} Installment`}
                                    placeholder={"eg. 3rd"}
                                    disabled
                                    className={classNames(
                                      "focus:ring-primary-500 focus:border-primary-500 border-gray-200 bg-gray-50",
                                      "block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px] border-0 focus:border"
                                    )}
                                  />
                                </td>
                                <td className="p-0 relative">
                                  <input
                                    key={idx}
                                    type={"number"}
                                    id={`${id}.amount`}
                                    name={`${id}.amount`}
                                    value={_.get(
                                      form.values,
                                      `${id}.amount`,
                                      ""
                                    )}
                                    onChange={({ target: { value } }) => {
                                      form.setFieldValue(`${id}.amount`, value);
                                      if (idx !== 0) {
                                        form.setFieldValue(
                                          `installments.${idx}.dueDate`,
                                          moment(
                                            _.get(
                                              form.values,
                                              `installments.${idx - 1}.dueDate`,
                                              ""
                                            )
                                          )
                                            .add(1, "M")
                                            .format(DATE_FORMAT)
                                        );
                                      }
                                    }}
                                    onBlur={form.handleBlur}
                                    placeholder={"eg. 2000"}
                                    step={0.01}
                                    min={0}
                                    max={form.values?.amount}
                                    className={classNames(
                                      _.get(form.errors, `${id}.amount`) &&
                                        _.get(form.touched, `${id}.amount`)
                                        ? "focus:ring-primary-500 focus:border-primary-500 ring-red-500 border-red-600 border"
                                        : "focus:ring-primary-500 focus:border-primary-500 border-gray-200 border-0 focus:border",
                                      "outline-none relative pl-[54px] block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px]"
                                    )}
                                  />
                                  <div className="absolute inset-y-0 flex items-center justify-center px-3.5">
                                    <span className="text-gray-700 text-sm">
                                      GHS
                                    </span>
                                  </div>
                                </td>
                                <td className="p-0 relative">
                                  <input
                                    key={idx}
                                    type={"date"}
                                    id={`${id}.dueDate`}
                                    name={`${id}.dueDate`}
                                    disabled
                                    value={_.get(
                                      form.values,
                                      `${id}.dueDate`,
                                      ""
                                    )}
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                    placeholder={"eg. 2000"}
                                    min={moment().format(DATE_FORMAT)}
                                    className={classNames(
                                      _.get(form.errors, `${id}.dueDate`) &&
                                        _.get(form.touched, `${id}.dueDate`)
                                        ? "focus:ring-primary-500 focus:border-primary-500 ring-red-500 border-red-600 border"
                                        : "focus:ring-primary-500 focus:border-primary-500 border-gray-200 border-0 focus:border",
                                      "outline-none block w-full sm:text-sm placeholder:font-light placeholder:text-gray-700 placeholder:text-xs h-[38px]"
                                    )}
                                  />
                                </td>
                                <td className="p-0 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400 text-right w-[38px] h-[38px] items-center justify-center flex">
                                  {!isLast && (
                                    <button
                                      type="button"
                                      onClick={wrapClick(() =>
                                        removeItem("installments", idx)
                                      )}
                                      className="inline-flex items-center rounded-full border border-transparent p-1 text-red-500 hover:bg-red-300 hover:text-red-900 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                    >
                                      <XMarkIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                  {form.errors?.installments && (
                    <div className="rounded-md bg-red-50 p-4 mt-2">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-red-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-red-800">
                            {lodash.isString(form?.errors?.installments)
                              ? form?.errors?.installments
                              : lodash
                                  .chain(form?.errors?.installments)
                                  .map((installment: any, index) =>
                                    installment
                                      ? `${numeral(index + 1).format(
                                          "0o"
                                        )} Installment: ${
                                          installment?.dueDate ||
                                          installment?.amount
                                        }`
                                      : ""
                                  )
                                  .filter((i) => !!i)
                                  .value()}
                          </h3>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="col-span-3 text-sm font-medium text-gray-700">
                  <div className="rounded-md bg-yellow-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationTriangleIcon
                          className="h-5 w-5 text-yellow-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-yellow-800">
                          A whole debt of GHS{" "}
                          {numeral(form.values?.amount).format("0,0.00")} would
                          be sent to the prepayment system as debt.
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(form.handleSubmit)}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default DebtInfoForm;
