import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { TextArea, TextInput } from "components";
import { FormikProps } from "formik";
import moment from "moment";
import numeral from "numeral";
import { FC } from "react";

type Props = {
  debt: any;
  form: FormikProps<{
    reason: string;
    code: string;
  }>;
};

const CompleteCreditForm: FC<Props> = ({ form, debt }) => {
  return (
    <div className="space-y-6 divide-y no-scrollbar divide-gray-200 flex-1 overflow-y-auto">
      <div className="">
        <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3">
          <div>
            <div className="grid grid-cols-4 gap-4">
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Debt Code
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {debt?.code || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Debt Amount
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  GHS {numeral(debt?.amount).format("0,0.00")}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Created At
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {moment(debt?.createdAt).format("DD/MM/YYYY hh:mm A")}
                </div>
              </div>
              <div className="col-start-1">
                <span className="block text-sm font-light text-gray-700">
                  Service Point Number
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {debt?.servicePoint?.code || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Account Number
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {debt?.servicePoint?.accountCode || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Meter Number
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {debt?.meter?.code || "N/A"}
                </div>
              </div>
              <div className="">
                <span className="block text-sm font-light text-gray-700">
                  Customer's Name
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {debt?.servicePoint?.customerOrganization?.name ||
                    debt?.servicePoint?.customerRepresentative?.fullName ||
                    "N/A"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <div className="grid grid-cols-4 gap-6">
          <div className="col-span-4">
            <TextArea
              id="reason"
              label="Cancellation Reason"
              required={true}
              placeholder=""
              {...form}
            />
          </div>
          <div className="col-span-1">
            <TextInput
              id="code"
              label="Debt Code"
              required={true}
              placeholder={debt?.code}
              {...form}
            />
          </div>
          <div className="col-span-3 pt-[24px]">
            <div className="rounded-md bg-yellow-50 border border-yellow-400 px-2 flex items-center h-[38px]">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon
                    className="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">
                    Enter Debt Code to proceed with cancellation
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompleteCreditForm;
