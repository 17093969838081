import { StepWizard } from "components";
import { FormikProps } from "formik";
import { FC, useMemo, useState } from "react";
import SummaryPage from "./summary";
import formSteps from "./form-steps";
import numeral from "numeral";
import moment from "moment";

interface ScheduleDebtFormProps {
  debt: any;
  form: FormikProps<{
    installmentInfo: {
      sendWholeDebt: boolean;
      installments: any[];
    };
  }>;
}

const ScheduleDebtForm: FC<ScheduleDebtFormProps> = ({ form, debt }) => {
  const steps = useMemo(
    () =>
      [
        ...formSteps,
        { name: "Preview", FormComponent: SummaryPage, accessor: "summary" },
      ].map((rawStep, href) => ({ ...rawStep, href })),
    []
  );

  const [step, setStep] = useState(0);
  const [lastStep, setLastStep] = useState(0);

  const onCancel = () => {
    setStep(0);
    setLastStep(0);
    form.resetForm();
  };

  const handleNextStep = (_step: string) => (_values: any) => {
    // check if last step
    form.setFieldValue(_step, _values);
    setStep(step + 1);
    setLastStep(step === lastStep ? lastStep + 1 : lastStep);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleCancel = () => {
    form.resetForm();
    onCancel();
  };

  const renderForm = () => {
    const { FormComponent, accessor } = steps[step || 0];
    return (
      <FormComponent
        handleNext={handleNextStep(accessor)}
        initialValues={(form.values as any)[accessor]}
        values={form.values}
        handlePrevious={handlePreviousStep}
        handleCancel={handleCancel}
        handleStep={setStep}
        handleSubmit={form.handleSubmit}
        submitLoading={form.isSubmitting}
        steps={formSteps}
        debt={debt}
      />
    );
  };

  return (
    <div className='bg-white dark:bg-gray-900 shadow-xl flex flex-col flex-1 overflow-hidden relative min-h-[75vh]'>
      <div className='p-6'>
        <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3'>
          <div>
            <div className="grid grid-cols-4 gap-4">
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Debt Code
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {debt?.code || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                Debt Amount
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  GHS {numeral(debt?.amount).format("0,0.00")}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                Created At
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                {moment(debt?.createdAt).format("DD/MM/YYYY hh:mm A")}
                </div>
              </div>
              <div className="col-start-1">
                <span className="block text-sm font-light text-gray-700">
                  Service Point Number
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {debt?.servicePoint?.code || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Account Number
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {debt?.servicePoint?.accountCode || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Meter Number
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {debt?.meter?.code || "N/A"}
                </div>
              </div>
              <div className="">
                <span className="block text-sm font-light text-gray-700">
                  Customer's Name
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {debt?.servicePoint?.customerOrganization?.name ||
                    debt?.servicePoint?.customerRepresentative?.fullName ||
                    "N/A"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='px-6'>
        <StepWizard
          steps={steps}
          step={step}
          setStep={setStep}
          lastStep={lastStep}
        />
      </div>
      <div className='flex flex-1 overflow-hidden'>{renderForm()}</div>
    </div>
  );
};

export default ScheduleDebtForm;
