import { useEffect } from "react";
import { classNames, wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { AssignDebtorSetForm, Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_PLOT } from "./view-debtor-set";

const ASSIGN_DISCONNECTOR = gql`
  mutation AssignDebtorSet($id: ID!, $team: ID!) {
    assignDebtorSet(id: $id, team: $team) {
      _id
    }
  }
`;

export default function AssignTeamContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_PLOT, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [assignDebtorSet] = useMutation(ASSIGN_DISCONNECTOR);

  const form = useFormik({
    initialValues: {
      team: null,
    },
    onSubmit: async (values) => {
      await assignDebtorSet({
        variables: {
          id: searchParams.id,
          team: (values.team as any)?._id
        },
      }).then(({ data }) => {
        if (data.assignDebtorSet._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Team  Assigned Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not assign team",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      team: data?.plot?.assignedStaff?.team || null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.plot, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Assign Plot "
      description="Provide the details assign team to plot"
      size="4xl"
      loading={loading}
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={form.isSubmitting || !form.values.team}
            className={classNames(
              form.isSubmitting || !form.values.team
                ? "cursor-not-allowed"
                : "cursor-pointer",
              "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            )}
            onClick={wrapClick(form.handleSubmit)}
          >
            {form.isSubmitting ? "Assigning Team..." : "Assign Team"}
          </button>
        </>
      )}
    >
      {data?.plot?._id && <AssignDebtorSetForm form={form} />}
    </Modal>
  );
}
