import { StepWizard } from "components";
import { FormikProps } from "formik";
import { FC, useMemo, useState } from "react";
import formSteps from "./form-steps";

interface SubmitFinalBalanceFormProps {
  form: FormikProps<{
    recoveryInfo: {
      amount: number;
      enterAmountManually: boolean;
      finalReadingDate: string;
      previousPurchases: any[];
    };
    chargesInfo: {};
  }>;
  servicePoint: any;
  replacement: any;
}

const SubmitFinalBalanceForm: FC<SubmitFinalBalanceFormProps> = ({ form, servicePoint, replacement }) => {
  const steps = useMemo(
    () =>
      formSteps
        .filter((_step) => Object.keys(form.values).includes(_step.accessor))
        .map((_step, href) => ({ ..._step, href })),
    [Object.keys(form.values)]
  );

  const [step, setStep] = useState(0);
  const [lastStep, setLastStep] = useState(0);

  const onCancel = () => {
    setStep(0);
    setLastStep(0);
    form.resetForm();
  };

  const handleNextStep = (_step: string) => (_values: any) => {
    // check if last step
    form.setFieldValue(_step, _values);
    setStep(step + 1);
    setLastStep(step === lastStep ? lastStep + 1 : lastStep);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleCancel = () => {
    form.resetForm();
    onCancel();
  };

  const renderForm = () => {
    const { FormComponent, accessor } = steps[step || 0];
    return (
      <FormComponent
        handleNext={handleNextStep(accessor)}
        initialValues={(form.values as any)[accessor]}
        values={form.values}
        handlePrevious={handlePreviousStep}
        handleCancel={handleCancel}
        handleSubmit={form.handleSubmit}
        submitLoading={form.isSubmitting}
        servicePoint={servicePoint}
        replacement={replacement}
      />
    );
  };

  return (
    <div className="bg-white dark:bg-gray-900 shadow-xl flex flex-col flex-1 overflow-hidden relative min-h-[75vh]">
      <div className="px-6 pt-6">
        <StepWizard
          steps={steps}
          step={step}
          setStep={setStep}
          lastStep={lastStep}
        />
      </div>
      <div className="flex flex-1 overflow-hidden">{renderForm()}</div>
    </div>
  );
};

export default SubmitFinalBalanceForm;
