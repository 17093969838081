import ApolloProvider from "apollo";
import LayoutProvider from "layouts";
import NotificationProvider from "notifications";
import { Tooltip, TooltipProvider } from "react-tooltip";
import RoutesProvider from "router";
// import TranslationProvider from 'translation';

function App() {
  return (
    // <TranslationProvider>
    <ApolloProvider>
      <NotificationProvider>
        <RoutesProvider>
          <LayoutProvider />
          <Tooltip id="tooltip" />
        </RoutesProvider>
      </NotificationProvider>
    </ApolloProvider>
    // </TranslationProvider>
  );
}

export default App;
