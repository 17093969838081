import { FC } from "react";
import { wrapClick } from "utils";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import numeral from "numeral";
import moment from "moment";

interface SummaryPageProps {
  handlePrevious: () => void;
  handleStep: (step: number) => void;
  handleSubmit: () => void;
  handleCancel: () => void;
  submitLoading: boolean;
  steps: any[];
  values: any;
}

const SummaryPage: FC<SummaryPageProps> = ({
  handlePrevious,
  handleStep,
  handleCancel,
  submitLoading,
  steps,
  ...form
}) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <form
      onSubmit={form.handleSubmit}
      className="flex-1 flex flex-col overflow-hidden"
    >
      <div className="space-y-6 divide-y divide-gray-200 p-6 overflow-y-auto flex-1">
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div className="col-span-2">
            <span className="flex flex-grow flex-col">
              <span className="text-sm font-medium text-gray-700">
                Send Whole Debt?
              </span>
            </span>
            <div className="flex items-center justify-between mt-1  h-[38px]">
              <span className="text-sm text-gray-500">
                {form.values?.installmentInfo?.sendWholeDebt ? "Yes" : "No"}
              </span>
            </div>
          </div>
          {!form.values?.installmentInfo.sendWholeDebt ? (
            <div className="col-span-3">
              <span className="block text-sm font-medium text-gray-700">
                Installments *
              </span>
              <div className="mt-2 w-full ">
                <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
                  <thead className="bg-gray-50">
                    <tr className="divide-x divide-gray-200">
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                      >
                        Plan Number
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-2 text-right text-sm font-semibold text-gray-900"
                      >
                        Amount(GHS)
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                      >
                        Due Date
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {form?.values?.installmentInfo?.installments?.map(
                      (step: any, idx: string) => (
                        <tr key={idx} className="divide-x divide-gray-200">
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">
                            {numeral(idx + 1).format("0o")} Installment
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">
                            {numeral(step?.amount || 0).format("0,0.00")}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {moment(step?.dueDate || 0).format(dateFormat)}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="col-span-3 text-sm font-medium text-gray-700">
              <div className="rounded-md bg-yellow-50 p-4">
                <div className="flex">
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">
                      A whole debt of GHS{" "}
                      {numeral(
                        form.values?.installmentInfo?.installments?.[0]?.amount
                      ).format("0,0.00")}{" "}
                      would be sent to the prepayment system as debt.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="submit"
          disabled={submitLoading}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {submitLoading ? "Submitting..." : "Submit"}
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handleCancel)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default SummaryPage;
