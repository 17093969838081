import { gql, useQuery } from "@apollo/client";
import { Shimmers, TableComponent } from "components";
import ActionButton, { Action } from "components/buttons/action-button";
import { Avatar, SelectInput, StatusBadge } from "components/core";
import config from "config";
import numeral from "numeral";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useTableData, useUrlState, wrapClick, wrapOnchange } from "utils";
import ViewDebtorContainer from "./view-debtor";

const GET_DEBTORS = gql`
  query GetDebtors(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $block: ID
    $round: ID
    $plot: ID
    $count: PositiveInt
    $serviceClasses: [ServiceClass]
    $accountType: AccountType
  ) {
    rows: getDebtors(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      block: $block
      round: $round
      plot: $plot
      count: $count
      serviceClasses: $serviceClasses
      accountType: $accountType
    ) {
      _id
      code
      geoCode
      customer {
        _id
        code

        organization {
          name
        }
        representative {
          fullName
          phoneNumber
          ghanaCardNumber
        }
      }
      meterCode
      accountCode
      meterLocation
      contractedDemand
      serviceType
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      block {
        _id
        code
        name
      }
      round {
        _id
        code
        name
      }
      plot {
        _id
        code
        name
      }
      itinerary {
        _id
        code
        description
      }
      qrCode
      ghanaPostAddress
      address
      propertyCode
      qrCode
      accountData {
        lastPaymentDate
        debtAmount
        toBeDisconnected
      }
      meta {
        lastReadingDate
        lastReadingValue
        lastBillDate
        lastBillValue
      }
      status
      createdAt
      updatedAt
    }
    count: getDebtorsCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      block: $block
      round: $round
      plot: $plot
      count: $count
      serviceClasses: $serviceClasses
      accountType: $accountType
    )
  }
`;

const ManageDebtors: FC<{ plot?: string; prefix?: string }> = ({
  plot,
  prefix = "",
}) => {
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          [prefix + "id"]: id,
          [prefix + "modal"]: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();

  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState(prefix + "modal");
  const [sltStatus, setSltStatus] = useUrlState("sltStatus");
  const [accountType, setAccountType] = useUrlState("accountType");
  const [sort, setSort] = useUrlState("sort");

  const filter = useMemo(
    () => ({
      page: (searchParams as any)?.[prefix + "page"] || 1,
      pageSize: (searchParams as any)?.[prefix + "pageSize"] || 12,
      sort: (searchParams as any)?.[prefix + "sort"] || "",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      plot: plot || undefined,
      count: parseInt(searchParams.count || "") || undefined,
      accountType: accountType || undefined,
      ...(sltStatus
        ? {
            serviceClasses:
              sltStatus === "NSLT"
                ? ["Residential", "NonResidential"]
                : ["Industrial", "SpecialLoadTariff"],
          }
        : {}),
    }),
    [searchParams, plot, sltStatus, accountType, prefix]
  );

  const { data, loading, networkStatus, refetch } = useQuery(GET_DEBTORS, {
    variables: filter,
    notifyOnNetworkStatusChange: false,
  });
  const records = useTableData(data || {});

  useEffect(() => {
    if (prefix) {
      navigate({
        search: (old: any) => ({
          ...old,
          ...(!old?.[prefix + "page"]
            ? { [prefix + "page"]: config.constants.page }
            : {}),
          ...(!old?.[prefix + "pageSize"]
            ? { [prefix + "pageSize"]: config.constants.pageSize }
            : {}),
            ...(!old?.[prefix + "sort"]
              ? { [prefix + "sort"]: "-accountData.debtAmount" }
              : {}),
        }),
      });
    }
  }, [navigate, prefix]);

  return (
    <>
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"debtors"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={false}
            renderHeaderItems={() => (
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex space-x-2">
                <SelectInput
                  id={"sltStatus"}
                  labelHidden={true}
                  values={{ sltStatus }}
                  handleChange={wrapOnchange(setSltStatus)}
                  handleBlur={undefined}
                  options={[
                    { label: "--- Select SLT Status ---", value: "" },
                    { label: "NSLT", value: "NSLT" },
                    { label: "SLT", value: "SLT" },
                  ]}
                />
                <SelectInput
                  id={"accountType"}
                  labelHidden={true}
                  values={{ accountType }}
                  handleChange={wrapOnchange(setAccountType)}
                  handleBlur={undefined}
                  options={[
                    { label: "--- Select Account Type ---", value: "" },
                    { label: "Private", value: "Private" },
                    { label: "MDA", value: "MDA" },
                  ]}
                />
                <SelectInput
                  id={"sort"}
                  labelHidden={true}
                  values={{ sort }}
                  handleChange={wrapOnchange(setSort)}
                  handleBlur={undefined}
                  options={[
                    { label: "--- Sort By ---", value: "" },
                    { label: "Debt Amount", value: "-accountData.debtAmount" },
                    { label: "Geo Code", value: "geoCode" },
                  ]}
                />
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  SPN | Geo Code
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Account Code | Meter No
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Customer
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Contact Info
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Debt Amount
                </th>
                {plot && (
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Priority
                  </th>
                )}
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.AvatarShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                {plot && (
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.SingleShimmer />
                  </td>
                )}
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={1} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.code || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.geoCode || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.accountCode || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.meterCode || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <Avatar
                        disabled={true}
                        alt={
                          item?.customer?.representative?.fullName?.trim() ||
                          item?.customer?.organization?.name?.trim() ||
                          item?.customer?.representative?.fullName?.trim() ||
                          "N A"
                        }
                        src={
                          item?.customer?.representative?.profileImageUrl ||
                          item?.customer?.representative?.profileImageUrl ||
                          ""
                        }
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-gray-900 dark:text-gray-100">
                        {item?.customer?.representative?.fullName?.trim() ||
                          item?.customer?.organization?.name?.trim() ||
                          item?.customer?.representative?.fullName?.trim() ||
                          "N/A"}
                      </div>
                      <div className="text-gray-500 dark:text-gray-400">
                        {item?.customer?.code || "N/A"}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.customer?.representative?.phoneNumber ||
                      item?.customer?.representative?.phoneNumber ||
                      "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.customer?.representative?.emailAddress ||
                      item?.customer?.representative?.emailAddress ||
                      "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    GHS{" "}
                    {numeral(item?.accountData?.debtAmount || 0).format(
                      "0,0.00"
                    )}{" "}
                  </div>
                </td>
                {plot && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                    <StatusBadge
                      status={
                        item?.accountData?.toBeDisconnected
                          ? "High"
                          : "Low"
                      }
                      options={{
                        Low: "bg-gray-100 text-gray-800",
                        High: "bg-red-100 text-red-800",
                      }}
                    />
                  </td>
                )}
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className="space-x-1">
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(item?._id, "view")}
                    />
                  </div>
                </td>
              </tr>
            )}
            paginationPrefix={prefix}
          />
        </div>
      </div>

      {!!(searchParams as any)?.[prefix + "id"]?.length && (
        <>
          <ViewDebtorContainer
            open={modal === "view"}
            prefix={prefix}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </>
  );
};

export default ManageDebtors;
