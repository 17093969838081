import moment from "moment";
import lodash from "lodash";
import * as Yup from "yup";

export const InstallmentInfoFormSchema = (totalAmountDue: number) => Yup.object().shape({
  sendWholeDebt: Yup.boolean().required(),
  installments: Yup.array()
    .of(
      Yup.object({
        amount: Yup.number().required("Installment Amount Is Required"),
        dueDate: Yup.date()
          .min(new Date(), ({min}) => `Installment Due Date Should Be Greater Than ${moment(min).format("DD/MM/YYYY")}`)
          .test(
            "dueDate",
            "Installment Due Date Should Be Less Than Previous Installment",
            function (current, { options }: any) {
            if (options?.index > 0) {
              const previous =
                options?.from?.[1]?.value?.installments?.[options?.index - 1];
              return moment(current).isAfter(moment(previous?.dueDate));
            }
            return true;
          })
          .required("Installment Due Date Is Required"),
      })
    )
    .test("sum", "Installments do not sum up to Debt Amount",function (current) {
      const totalAmount = lodash.sumBy(current, "amount");
      return lodash.round(totalAmount, 2) === totalAmountDue;
    }),
});

export const ScheduleDebtFormSchema = (totalAmountDue: number) => Yup.object().shape({
  installmentInfo: InstallmentInfoFormSchema(totalAmountDue),
});
