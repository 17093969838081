import CustomerInfoForm from "./customer-info-form";
import DebtInfoForm from "./debt-info-form";

const formSteps = [
  {
    name: 'Customer Info',
    description: "Information about agent to attend to service order.",
    accessor: "customerInfo",
    FormComponent: CustomerInfoForm,
  },
  {
    name: 'Debt Info',
    description: "Information about user to approve to service order.",
    accessor: "debtInfo",
    FormComponent: DebtInfoForm,
  },
]

export default formSteps;
