import { FC } from "react";
import { useUrlState } from "utils";

import { setCurrentDistrict } from "apollo/cache/auth";
import { Header } from "components";
import { DistrictPicker, RegionPicker } from "containers";

interface OfficeHeaderProps {
  renderActions?: FC<{}>;
}

const OfficeHeader: FC<OfficeHeaderProps> = ({ renderActions }) => {
  const [region, setRegion] = useUrlState("region");
  const [district, setDistrict] = useUrlState("district");

  return (
    <Header
      renderActions={() => (
        <div className="flex items-center space-x-3">
          <RegionPicker
            id="region"
            labelHidden={true}
            setFieldValue={(_field: string, value: string) => {
              setRegion(value || undefined);
              setDistrict(undefined);
            }}
            values={{ region }}
            rawId={true}
          />
          {region && (
            <DistrictPicker
              id="district"
              labelHidden={true}
              setFieldValue={(_field: string, value: string) => {
                setCurrentDistrict(value);
                setDistrict(value || undefined);
              }}
              values={{ district }}
              filter={{ region }}
              rawId={true}
            />
          )}
          {renderActions && (
            <>
              <div className="h-6 w-px bg-gray-300" />
              {renderActions?.({})}
            </>
          )}
        </div>
      )}
    />
  );
};

export default OfficeHeader;
