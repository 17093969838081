import { gql, useQuery } from "@apollo/client";
import { Avatar, SelectInput, Shimmers, TableComponent } from "components";
import ActionButton, { Action } from "components/buttons/action-button";
import numeral from "numeral";
import { FC, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  useTableData,
  useUrlState,
  withPermissions,
  wrapClick,
  wrapOnchange,
} from "utils";
import AssignPlotDisconnectorContainer from "./assign";
import ViewDebtorSetContainer from "./view-debtor-set";
import DebtorsModal from "./debtors-index";

const GET_PLOTS = gql`
  query GetDebtorSets(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $block: ID
    $round: ID
    $count: PositiveInt
    $status: DebtorSetAssignmentStatus
    $serviceClasses: [ServiceClass]
    $accountType: AccountType
  ) {
    rows: getDebtorSets(
      status: $status
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      block: $block
      round: $round
      count: $count
      serviceClasses: $serviceClasses
      accountType: $accountType
    ) {
      _id
      plot {
        _id
        code
        name
        region {
          _id
        }
        district {
          _id
        }
        block {
          _id
        }
        round {
          _id
        }
        meta {
          itinerariesCount
          propertiesCount
          customersCount
        }
        debtMeta {
          status
          totalDebtors
          totalDebtAmount
          lowestDebtAmount
          highestDebtAmount
        }
        prioritizedDebtMeta {
          totalDebtors
          totalDebtAmount
          lowestDebtAmount
          highestDebtAmount
        }
        assignedStaff {
          team {
            _id
            code
            name
          }
        }
        createdAt
        updatedAt
      }
      meta {
        totalDebtors
        totalDebtAmount
        lowestDebtAmount
        highestDebtAmount
      }
    }
    count: getDebtorSetsCount(
      status: $status
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      block: $block
      round: $round
      count: $count
      serviceClasses: $serviceClasses
      accountType: $accountType
    )
  }
`;

const ManageDebtorSets: FC<{
  status: "Assigned" | "Unassigned";
}> = ({ status }) => {
  const dispatchAction =
    (
      id: string,
      action: Exclude<Action, "expand" | "goto" | "clone">,
      prefix = ""
    ) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          [prefix + "modal"]: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [debtorSetModal, setDebtorSetModal] = useUrlState("debtorsetmodal");
  const [search, setSearch] = useUrlState("search");
  const [sltStatus, setSltStatus] = useUrlState("sltStatus");
  const [accountType, setAccountType] = useUrlState("accountType");

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      district: searchParams.district || undefined,
      region: searchParams.region || undefined,
      status,
      search: searchParams.search
        ? (searchParams.search || "").toString()
        : undefined,
      sort: searchParams.sort || "",
      count: parseInt(searchParams.count || "") || undefined,
      accountType: accountType || undefined,
      ...(sltStatus
        ? {
            serviceClasses:
              sltStatus === "NSLT"
                ? ["Residential", "NonResidential"]
                : ["Industrial", "SpecialLoadTariff"],
          }
        : {}),
    }),
    [searchParams, status, sltStatus, accountType]
  );

  const { data, loading, networkStatus, refetch } = useQuery(GET_PLOTS, {
    variables: filter,
    notifyOnNetworkStatusChange: false,
  });
  const records = useTableData(data || {});

  return (
    <>
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"debtor sets"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            onSearchClicked={setSearch}
            disableSearch={search?.length < 17}
            hasSearch={true}
            renderHeaderItems={() => (
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex space-x-2">
                <SelectInput
                  id={"sltStatus"}
                  labelHidden={true}
                  values={{ sltStatus }}
                  handleChange={wrapOnchange(setSltStatus)}
                  handleBlur={undefined}
                  options={[
                    { label: "--- Select SLT Status ---", value: "" },
                    { label: "NSLT", value: "NSLT" },
                    { label: "SLT", value: "SLT" },
                  ]}
                />
                <SelectInput
                  id={"accountType"}
                  labelHidden={true}
                  values={{ accountType }}
                  handleChange={wrapOnchange(setAccountType)}
                  handleBlur={undefined}
                  options={[
                    { label: "--- Select Account Type ---", value: "" },
                    { label: "Private", value: "Private" },
                    { label: "MDA", value: "MDA" },
                  ]}
                />
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Code | Name
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Min | Max Amount (GHS)
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Total Debt Amount (GHS)
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Total Debtors
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Disconnection Team
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.AvatarShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={3} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                onClick={wrapClick(
                  dispatchAction(item._id, item?.meta, "view")
                )}
              >
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100">
                    {item?.plot?.code || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-100 truncate max-w-[20rem]">
                    {item?.plot?.name || "N/A"}
                  </div>
                </td>

                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100 text-right">
                    {numeral(item?.meta?.lowestDebtAmount || 0).format(
                      "0,0.00"
                    )}{" "}
                  </div>
                  <div className="text-gray-500 dark:text-gray-100 text-right">
                    {numeral(item?.meta?.highestDebtAmount || 0).format(
                      "0,0.00"
                    )}{" "}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100 text-right">
                    {numeral(item?.plot?.debtMeta?.totalDebtAmount || 0).format(
                      "0,0.00"
                    )}{" "}
                    total
                  </div>
                  <div className="text-gray-500 dark:text-gray-100 text-right">
                    {numeral(
                      item?.plot?.prioritizedDebtMeta?.totalDebtAmount || 0
                    ).format("0,0.00")}{" "}
                    prioritized
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100 text-right">
                    {numeral(item?.plot?.debtMeta?.totalDebtors || 0).format(
                      "0,0"
                    )}{" "}
                    total
                  </div>
                  <div className="text-gray-500 dark:text-gray-100 text-right">
                    {numeral(item?.plot?.prioritizedDebtMeta?.totalDebtors || 0).format(
                      "0,0"
                    )}{" "}
                    prioritized
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <Avatar
                        disabled={true}
                        alt={
                          (
                            item?.plot?.assignedStaff?.team?.name || ""
                          )?.trim() || "N A"
                        }
                        src={
                          item?.plot?.assignedStaff?.team?.profileImageUrl || ""
                        }
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-gray-900 dark:text-gray-100">
                        {item?.plot?.assignedStaff?.team?.name || "N A"}
                      </div>
                      <div className="text-gray-500 dark:text-gray-400">
                        {item?.plot?.assignedStaff?.team?.code || "N/A"}
                      </div>
                    </div>
                  </div>
                </td>

                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className="space-x-1">
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(
                        item?._id,
                        "view"
                      )}
                    />
                    {withPermissions(["*:*", "plots:*", "plots:manage"])(
                      <ActionButton
                        action={
                          !item?.plot.assignedStaff ? "assign" : "reassign"
                        }
                        onClick={dispatchAction(
                          item?._id,
                          "assign"
                        )}
                        tooltip={
                          !item?.plot.assignedStaff ? "Assign" : "Reassign"
                        }
                        disabled={item?.status === "Completed"}
                      />
                    )}
                    {withPermissions([
                      "*:*",
                      "service-points:*",
                      "service-points:manage",
                    ])(
                      <ActionButton
                        action="expand"
                        onClick={dispatchAction(
                          item?._id,
                          "view-service-points",
                          "debtorset"
                        )}
                      />
                    )}
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {!!searchParams.id?.length && (
        <>
          <ViewDebtorSetContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
          <DebtorsModal
            open={debtorSetModal === "view-service-points"}
            setOpen={(val: boolean) =>
              setDebtorSetModal(val ? "view-service-points" : undefined)
            }
          />
          {withPermissions(["*:*", "plots:manage", "plots:*"])(
            <AssignPlotDisconnectorContainer
              open={modal === "assign"}
              setOpen={(val: boolean) => setModal(val ? "assign" : undefined)}
              refetch={refetch}
            />
          )}
        </>
      )}
    </>
  );
};

export default ManageDebtorSets;
