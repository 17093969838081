import { FC, useState } from "react";
import { classNames, wrapClick } from "utils";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import {
  ReconnectionServiceOrderResolutionView,
  Modal,
  ServiceOrderHistoryView,
  ReconnectionServiceOrderView,
  ReconnectionServiceOrderInvoiceView,
} from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { Action } from "components/buttons/action-button";
import { currentConfigVar } from "apollo/cache/config";

export const GET_SERVICE_ORDER = gql`
  query GetReconnectionServiceOrder($id: ID!) {
    serviceOrder: getReconnectionServiceOrder(id: $id) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      contactPerson {
        fullName
        phoneNumber
      }
      location {
        address
        community
      }
      assigner {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      assignee {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      approver {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      resolution {
        reading {
          readingDate
          readingValue
          readingImageUrl
        }
        notes
      }
      reconnectionFeeInvoice {
        _id
        code
        type
        invoiceTo {
          fullName
          phoneNumber
          accountNumber
        }
        items {
          title
          unitCost
          quantity
          cost
        }
        invoiceItem
        invoiceItemType
        amount
        status
        paidAt
        createdAt
        updatedAt
      }
      category
      status
      priority
      servicePoint {
        _id
        code
        accountCode
        meterCode
      }
      history {
        actor {
          ... on User {
            _id
            firstName
            lastName
            code
          }
        }
        assignee {
          ... on User {
            _id
            firstName
            lastName
            code
          }
        }
        actorType
        assigneeType
        timestamp
        action
        notes
      }
      notes
      estimatedResolutionDate
      assignedAt
      startedAt
      resolvedAt
      completedAt
      createdAt
      updatedAt
    }
  }
`;

const orderTabs = [
  {
    name: "Order Details",
    href: "OrderDetails",
    activeStatues: [
      "PendingInvoice",
      "Pending",
      "Assigned",
      "Resolved",
      "Completed",
      "InProgress",
    ],
  },
  {
    name: "Order Invoice",
    href: "OrderInvoice",
    activeStatues: [
      "PendingInvoice",
      "Pending",
      "Assigned",
      "Resolved",
      "Completed",
      "InProgress",
    ],
  },
  {
    name: "Order History",
    href: "OrderHistory",
    activeStatues: ["Assigned", "Resolved", "Completed", "InProgress"],
  },
  {
    name: "Resolution Details",
    href: "ResolutionDetails",
    activeStatues: ["Resolved", "Completed"],
  },
];

interface IViewReconnectionServiceOrderContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
}

const ViewReconnectionServiceOrderContainer: FC<
  IViewReconnectionServiceOrderContainerProps
> = ({ open, setOpen }) => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [orderTab, setOrderTab] = useState("OrderDetails");
  const { data, refetch, loading } = useQuery(GET_SERVICE_ORDER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
    pollInterval,
  });
  const dispatchAction =
    (action: Exclude<Action, "expand" | "goto" | "clone">) => () => {
      navigate({
        search: (old) => ({
          ...old,
          modal: action,
        }),
      });
    };
  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };

  return (
    <Modal
      loading={loading}
      open={open}
      setOpen={() => {
        setOpen(false);
        navigate({
          search: (old) => ({
            ...old,
            modal: undefined,
            id: undefined,
          }),
        });
      }}
      hideActions={false}
      hidePadding={true}
      size='4xl'
      title='Service Order Information'
      description='Details of reading set are shown below'
      renderActions={() => (
        <>
          {["Pending", "Assigned"].includes(data?.serviceOrder?.status) && (
            <>
              {!data?.serviceOrder?.assignee ? (
                <button
                  type='button'
                  className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
                  onClick={wrapClick(dispatchAction("assign"))}
                >
                  Assign
                </button>
              ) : (
                <button
                  type='button'
                  className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
                  onClick={wrapClick(dispatchAction("reassign"))}
                >
                  Reassign
                </button>
              )}
            </>
          )}
          {["InProgress"].includes(data?.serviceOrder?.status) && (
            <button
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={wrapClick(dispatchAction("resolve"))}
            >
              Resolve
            </button>
          )}
          {["Resolved"].includes(data?.serviceOrder?.status) && (
            <button
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={wrapClick(dispatchAction("approve"))}
            >
              Approve
            </button>
          )}
          {["Resolved"].includes(data?.serviceOrder?.status) && (
            <button
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={wrapClick(dispatchAction("disapprove"))}
            >
              Disapprove
            </button>
          )}
        </>
      )}
    >
      {data?.serviceOrder?._id && (
        <>
          <div className='block'>
            <div className='border-b border-gray-200 bg-white px-6'>
              <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
                {orderTabs.map((_orderTab) => (
                  <button
                    key={_orderTab.name}
                    onClick={wrapClick(__setOrderTab(_orderTab.href))}
                    disabled={
                      !_orderTab.activeStatues.includes(
                        data?.serviceOrder?.status
                      )
                    }
                    className={classNames(
                      !_orderTab.activeStatues.includes(
                        data?.serviceOrder?.status
                      )
                        ? "cursor-not-allowed"
                        : "cursor-pointer",
                      orderTab === _orderTab.href
                        ? "border-primary-500 text-primary-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                      "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                    )}
                    aria-current={
                      orderTab === _orderTab.href ? "page" : undefined
                    }
                  >
                    {_orderTab.name}
                  </button>
                ))}
              </nav>
            </div>
          </div>
          <div className='flex-1 w-full min-h-[65vh] max-h-[65vh] overflow-y-auto  sm:p-6'>
            {orderTab === "OrderDetails" && (
              <ReconnectionServiceOrderView serviceOrder={data?.serviceOrder} />
            )}
            {orderTab === "OrderInvoice" && (
              <ReconnectionServiceOrderInvoiceView
                serviceOrder={data?.serviceOrder}
                refetch={refetch}
              />
            )}
            {orderTab === "OrderHistory" && (
              <ServiceOrderHistoryView
                history={data?.serviceOrder?.history ?? []}
              />
            )}
            {orderTab === "ResolutionDetails" && (
              <ReconnectionServiceOrderResolutionView
                resolution={data?.serviceOrder?.resolution}
              />
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default ViewReconnectionServiceOrderContainer;
