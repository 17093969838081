import { ReactElement } from "react";
import { ReactLocation, MakeGenerics } from "react-location";
import { parseSearch, stringifySearch } from "react-location-jsurl";

export type LocationGenerics = MakeGenerics<{
  Search: {
    redirect?: string;
    fromDate?: string;
    toDate?: string;
    page?: number;
    pageSize?: number;
    search?: string;
    sort?: string;
    modal?:
    | "create"
    | "update"
    | "updateIdentity"
    | "updateLocation"
    | "updateTariff"
    | "view"
    | "viewComplaint"
    | "configure"
    | "calculate"
    | "assign"
    | "schedule"
    | "send"
    | "resolve"
    | "approve"
    | "cancel"
    | "reject"
    | "disapprove"
    | "reassign"
    | "export"
    | "import"
    | "investigate"
    | "initiate"
    | "complete"
    | "submit"
    | "delete"
    | "upload"
    | "regularization"
    | "simulator"
    | "reset"
    | "correct"
    | "suspend"
    | "restore"
    | "investigate"
    | "terminate"
    | "view-service-points"
    ;
    debtorsetmodal?: string;
    debtorsmodal?: string;
    debtormodal?: string;
    id?: string;
    region?: string;
    district?: string;
    block?: string;
    source?: string;
    round?: string;
    plot?: string;
    itinerary?: string;
    property?: string;
    role?: "SuperAdmin" | "Admin" | "Supervisor" | "Reader";
    month?: number;
    year?: number;
    tariffClass?: string;
    customerType?: string;
    serviceType?: string;
    serviceClass?: string;
    view?: "day" | "week" | "month" | "quarter" | "year" | "custom";
    orderStatus?: string;
    adoraSyncStatus?: string;
    orderType?: string;
    status?: string;
    token?: string;
    priority?: string;
    category?: string;
    exceptionStatus?: string;
    model?: string;
    minAge?: number;
    maxAge?: number;
    minDebtAmount?: number;
    maxDebtAmount?: number;
    balanceStatus?: string;
    location?: string;
    type?: string;
    count?: string;
    sltStatus?: string;
    accountType?: string;
    meta?: {
      totalDebtors: number;
      totalDebtAmount: number;
      lowestDebtAmount: number;
      highestDebtAmount: number;
    }
  };
  Params: {
    region?: string;
    district?: string;
    block?: string;
    round?: string;
    plot?: string;
    itinerary?: string;
    tariffClass?: string;
    readingSet?: string;
    serviceRequest?: string;
    servicePoint?: string;
    amount?: string;
  };
  RouteMeta: {
    layout?: "App" | "Auth";
    breadcrumb: (params: LocationGenerics["Params"]) => ReactElement;
    section?: "General" | "Structure" | "Configuration" | "Debt" | "Users & Profiles";
  };
}>;

const location = new ReactLocation({
  parseSearch,
  stringifySearch
})

export default location;
