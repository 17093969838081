import { gql, useQuery } from "@apollo/client";

const GET_DEBTS = gql`
query GetDebts(
  $region: ID
  $district: ID
  $type: DebtType
  $status: DebtStatus
  $page: Int
  $pageSize: Int
  $search: String
  $searchFields: [String!]
  $sort: String
  $fromDate: Date
  $toDate: Date
) {
  count: getDebtsCount(
    region: $region
    district: $district
    type: $type
    status: $status
    search: $search
    searchFields: $searchFields
    fromDate: $fromDate
    toDate: $toDate
  )
  rows: getDebts(
    region: $region
    district: $district
    type: $type
    status: $status
    page: $page
    pageSize: $pageSize
    search: $search
    searchFields: $searchFields
    sort: $sort
    fromDate: $fromDate
    toDate: $toDate
  ) {
    _id
    code
    customerCode
    accountCode
    meterCode
    meter {
      _id
      code
      modelMeta {
        brandName
        modelName
      }
    }
    servicePointCode
    data {
      consumptionValue
      consumptionPeriod
      billPeriod
      billStartDate
      billEndDate
      billAmount
      extraChargesAmount
      totalAmountDue
    }
    amount
    type
    status
    createdAt
    updatedAt
    customer {
      code
      customerType

      organization {
        name
      }
      representative {
        phoneNumber
        profileImageUrl
        ghanaCardNumber
      }
    }
    servicePoint {
      code
      customer {
        code
        customerType
        representative {
          fullName
          phoneNumber
          profileImageUrl
          ghanaCardNumber
        }
        organization {
          name
        }
      }
    }
  }
}

`;

export const useDebts = (variables?: any) => {
  const { data, ...rest } = useQuery(GET_DEBTS, {
    variables,
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
  });

  return {
    debts: data?.rows,
    count: data?.count,
    ...rest,
  };
};
